import { FunctionComponent, ReactElement, useState } from 'react';
import { useQuery } from 'react-query';
import TaskDetails from './event';
import { useLocation } from 'react-router-dom';
import { head, isEmpty, split, trim } from 'lodash';
import { useErrorPage } from '../../hooks';
import { navigateToErrorPage } from '../navigate';
import CalendarService, { Location } from "../../services/calendar-service";
import { LinearProgress } from '@material-ui/core';

export const getDateWithHours = (hours: number = 0, minutes: number = 0): Date => {
  const d = new Date('10/10/2010');
  d.setHours(hours, minutes);
  return d;
};

export const parseTimeString = (time?: string | null): [number, number] => {
  if (time === null || isEmpty(trim(time))) {
    return [0, 0];
  }

  const keyValuePair = split(time, ':');
  if (keyValuePair.length !== 2) {
    return [0, 0];
  }

  const hours = parseInt(keyValuePair[0]);
  const minutes = parseInt(keyValuePair[1]);

  return [hours, minutes];
};

interface SearchParam {
  from: Date;
  to: Date;
  date: Date;
  id: string | null;
  rampId: string;
  serviceId: string;
  rampFrom:Date | null;
  rampTo:Date | null;
  locationId:string;
}

interface TaskRequestResult{
  isWholeDay: boolean;
  title: string;
  note: string;
  to: Date;
  from: Date;
  date: Date;
  id?: string;
  rampId:string;
  serviceId:string;
  locationId:string;
  rampFrom:Date | null;
  rampTo:Date | null;
  mark:string;
  customerOnSite:boolean;
}

const useSearch = (): SearchParam => {
  const { search } = useLocation();
  const p = new URLSearchParams(search);
  const rampFrom = p.get('rFrom');
  const rampTo = p.get('rTo');
  const locationId = p.get('locationId');

  
  return {
    from: getDateWithHours(...parseTimeString(p.get('from'))),
    to: getDateWithHours(...parseTimeString(p.get('to'))),
    date: p.get('d') ? new Date(p.get('d')!) : new Date(),
    id: p.get('id'),
    rampId:p.get('rampId') || '',
    serviceId:p.get('serviceId') || '',
    rampFrom: rampFrom ? getDateWithHours(...parseTimeString(rampFrom)) : null,
    rampTo: rampTo ? getDateWithHours(...parseTimeString(rampTo)) : null,
    locationId:!!locationId ? locationId : '',
  };
};

const getTask = async ({
  id,
  to,
  from,
  date,
  rampId,
  serviceId,
  rampFrom,
  rampTo,
  locationId
}: SearchParam): Promise<TaskRequestResult> => {
  if (id) {
    const task = await CalendarService.getTaskAsync(id);
    return {
      id,
      title: task.title,
      note: task.note || '',
      to: getDateWithHours(...parseTimeString(task.to)),
      from: getDateWithHours(...parseTimeString(task.from)),
      isWholeDay: task.isWholeDay,
      date: task.date,
      rampId:task.rampId,
      serviceId:task?.serviceId || '',
      rampFrom,
      rampTo,
      mark:task.kennzeichen || '',
      locationId:task.locationId ?? '',
      customerOnSite:task.customerOnSite,
    };
  }

  return {
    to,
    from,
    date: date,
    isWholeDay: false,
    title: '',
    note: '',
    rampId,
    serviceId,
    rampFrom,
    rampTo,
    mark:'',
    locationId,
    customerOnSite:false,
  };
};

const EventContainer: FunctionComponent = (): ReactElement | null => {
  const search = useSearch();
  const naviageToErroPage = useErrorPage();
  const [activeLocation, setActiveLocation] = useState<Location>();

  const { isLoading: isLocationsLoading, data: locations, isFetched } = useQuery(
    "locations",
    async ()=>{
      return  CalendarService.getLocationsAsync();
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 10,
      onSuccess(locations:Location[]) {
        if (locations.length === 0) {
          navigateToErrorPage("Calendar does not have locations!");
        }
        const activeLocation =
          locations.find((location) => location.locationId === search.locationId) ||
          head(locations);
        setActiveLocation(activeLocation);
      },
      initialData: [],
      onError: navigateToErrorPage,
    }
  );

  const { isLoading: isTaskLoading, data, isFetched: isTaskFetch } = useQuery(
    'task',
    () =>  getTask(search),
    {
      onError: naviageToErroPage,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: isFetched
    }
  );

  if (isLocationsLoading || isTaskLoading || !isTaskFetch || !isFetched) {
    return <LinearProgress />
  }
  
 
  return (data && activeLocation)  ? 
       <TaskDetails key={data.id} {...data} locations={locations!} locationId={activeLocation!.locationId}  activeLocation={activeLocation!}/> : 
       null;
};

export default EventContainer;
