import { useState , ChangeEvent, FunctionComponent, ReactElement, ReactSVGElement } from 'react';
import { Locale, startOfWeek, addWeeks } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { Profile } from '../../reducers/profile-reducer';
import clsx from 'clsx';


import Picker from './calendar-top-bar-picker';
import classes from './calendar-top-bar.module.scss';
import * as Labels from '../../unit/labels';
import CalendarService , { Location , DefaultLocationResponse} from '../../services/calendar-service';
import LocationSelect from './calendar-top-bar-select';
import { MenuItem , Tooltip , CircularProgress} from '@material-ui/core';
import { navigateToEventPage } from '../../routes/navigate';

interface PreviousWeekClickHandlerProps {
  onClick(): void;
}

const PreviousWeekClickHandler: FunctionComponent<PreviousWeekClickHandlerProps> = (props): ReactElement => {
  const { onClick } = props;
  return (
    <div className={classes.item_53}>
      <button type='button' onClick={onClick} className={clsx(classes.navigation_icons, classes.root_55, classes.top_bar_content)} tabIndex={-1}>
        <span className={classes.flex_container_41}>
          <i role='presentation' aria-hidden='true' className={clsx(classes.root_32, classes.icon_56)}>
            <svg viewBox='0 0 32 32' role='presentation' className={classes.app_svg} focusable='false'>
              <g className={classes.icons_default_fill}>
                <path
                  className={classes.icons_unfilled}
                  d='M19,22.97c0,0.14-0.05,0.25-0.15,0.35c-0.1,0.1-0.22,0.15-0.35,0.15c-0.14,0-0.25-0.05-0.35-0.15l-7-7 c-0.1-0.1-0.15-0.22-0.15-0.35c0-0.14,0.05-0.25,0.15-0.35l7-7c0.1-0.1,0.22-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15 C18.95,8.72,19,8.83,19,8.97c0,0.14-0.05,0.25-0.15,0.35l-6.64,6.65l6.64,6.65C18.95,22.72,19,22.83,19,22.97z'
                />
                <path
                  className={classes.icons_filled}
                  d='M19.5,8.97c0,0.14-0.03,0.27-0.07,0.39c-0.05,0.12-0.12,0.23-0.21,0.32l-6.3,6.29l6.3,6.29c0.09,0.09,0.17,0.2,0.21,0.32 c0.05,0.12,0.07,0.26,0.07,0.39c0,0.14-0.03,0.26-0.08,0.38c-0.05,0.12-0.13,0.23-0.22,0.32c-0.09,0.09-0.2,0.16-0.32,0.22 c-0.12,0.05-0.25,0.08-0.38,0.08c-0.28,0-0.52-0.1-0.71-0.29l-7-7c-0.19-0.19-0.29-0.43-0.29-0.71s0.1-0.52,0.29-0.71l7-7 c0.19-0.19,0.43-0.29,0.71-0.29c0.13,0,0.26,0.03,0.38,0.08C19,8.11,19.11,8.18,19.2,8.27c0.09,0.09,0.16,0.2,0.22,0.32 C19.47,8.71,19.5,8.84,19.5,8.97z'
                />
              </g>
            </svg>
          </i>
        </span>
      </button>
    </div>
  );
};

interface NextWeekClickHandlerProps {
  onClick(): void;
}

const NextWeekClickHandler: FunctionComponent<NextWeekClickHandlerProps> = (props): ReactElement => {
  const { onClick } = props;
  return (
    <div className={classes.item_53}>
      <button type='button' onClick={onClick} className={clsx(classes.navigation_icons, classes.root_55, classes.top_bar_content)} tabIndex={-1}>
        <span className={classes.flex_container_41}>
          <i role='presentation' aria-hidden='true' className={clsx(classes.root_32, classes.icon_56)}>
            <svg viewBox='0 0 32 32' role='presentation' className={classes.app_svg} focusable='false'>
              <g className={classes.icons_default_fill}>
                <path
                  className={classes.icons_unfilled}
                  d='M21,15.97c0,0.14-0.05,0.25-0.15,0.35l-7,7c-0.1,0.1-0.22,0.15-0.35,0.15c-0.14,0-0.25-0.05-0.35-0.15 c-0.1-0.1-0.15-0.22-0.15-0.35c0-0.13,0.05-0.25,0.15-0.35l6.64-6.65l-6.64-6.65C13.05,9.22,13,9.1,13,8.97 c0-0.14,0.05-0.25,0.15-0.35c0.1-0.1,0.22-0.15,0.35-0.15c0.14,0,0.25,0.05,0.35,0.15l7,7C20.95,15.72,21,15.83,21,15.97z'
                ></path>
                <path
                  className={classes.icons_filled}
                  d='M21.5,15.97c0,0.28-0.1,0.52-0.29,0.71l-7,7c-0.19,0.19-0.43,0.29-0.71,0.29c-0.14,0-0.26-0.03-0.38-0.08 c-0.12-0.05-0.23-0.13-0.32-0.22c-0.09-0.09-0.16-0.2-0.22-0.32c-0.05-0.12-0.08-0.25-0.08-0.38c0-0.13,0.02-0.27,0.07-0.39 c0.05-0.12,0.12-0.23,0.21-0.32l6.3-6.29l-6.3-6.29c-0.09-0.09-0.17-0.2-0.21-0.32C12.52,9.23,12.5,9.1,12.5,8.97 c0-0.13,0.03-0.26,0.08-0.38c0.05-0.12,0.13-0.23,0.22-0.32c0.09-0.09,0.2-0.16,0.32-0.22C13.24,8,13.36,7.97,13.5,7.97 c0.28,0,0.52,0.1,0.71,0.29l7,7C21.4,15.45,21.5,15.69,21.5,15.97z'
                ></path>
              </g>
            </svg>
          </i>
        </span>
      </button>
    </div>
  );
};

interface TopBarProps {
  activeLocation?: Location;
  weekStartDate: Date;
  locale?: Locale;
  locations: Location[];
  defaultLocation:DefaultLocationResponse | null;
  onWeekChange(weekStartDate: Date): void;
  onLocationChange(locationId: string): void;
  onDefaultLocationUpdate(location:DefaultLocationResponse):void;
}

const DefaultLocationIcon:FunctionComponent = ():ReactElement<ReactSVGElement>=>{
  return (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="rgb(87, 89, 161)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.157 4.56831C14.7566 1.1679 9.24344 1.1679 5.84303 4.56831C2.44262 7.96872 2.44262 13.4819 5.84303 16.8823C6.13887 17.1781 6.50614 17.5414 6.9449 17.9723L10.436 21.3681C11.3081 22.2116 12.6919 22.2117 13.5642 21.3684C13.8904 21.053 14.2023 20.7511 14.5 20.4625L13.5642 19.276L12.5215 20.29C12.2308 20.5711 11.7695 20.5711 11.4788 20.2899C10.2391 19.0909 9.20967 18.0905 8.39077 17.2891L6.90369 15.8216C4.08907 13.007 4.08907 8.4436 6.90369 5.62897C9.71832 2.81435 14.2817 2.81435 17.0963 5.62897C18.8292 7.36185 19.4952 9.7576 19.0943 12L20.5251 12.5021C21.101 9.72588 20.3117 6.72296 18.157 4.56831ZM15.0016 11.0005C15.0016 9.34281 13.6577 7.99897 12 7.99897C10.3423 7.99897 8.99847 9.34281 8.99847 11.0005C8.99847 12.6582 10.3423 14.0021 12 14.0021C13.6577 14.0021 15.0016 12.6582 15.0016 11.0005ZM10.4985 11.0005C10.4985 10.1712 11.1707 9.49897 12 9.49897C12.8293 9.49897 13.5016 10.1712 13.5016 11.0005C13.5016 11.8298 12.8293 12.5021 12 12.5021C11.1707 12.5021 10.4985 11.8298 10.4985 11.0005Z" fill="rgb(87, 89, 161)"/>
    <path d="M18.5 22C20.9853 22 23 19.9853 23 17.5C23 15.0147 20.9853 13 18.5 13C16.0147 13 14 15.0147 14 17.5C14 19.9853 16.0147 22 18.5 22ZM21.1678 16.014L17.7063 19.4755C17.5711 19.6107 17.352 19.6107 17.2168 19.4755L15.8322 18.0909C15.697 17.9557 15.697 17.7366 15.8322 17.6014C15.9673 17.4662 16.1865 17.4662 16.3217 17.6014L17.4615 18.7412L20.6783 15.5245C20.8135 15.3893 21.0327 15.3893 21.1678 15.5245C21.303 15.6596 21.303 15.8788 21.1678 16.014Z" fill="rgb(87, 89, 161)"/>
  </svg>
  )
}

const ChosenLocationIcon:FunctionComponent = ():ReactElement<ReactSVGElement>=>{
  return (
   <svg width="24" height="24" viewBox="0 0 24 24" fill="rgb(87, 89, 161)">
      <path d="M5.84303 4.56837C9.24344 1.16796 14.7566 1.16796 18.157 4.56837C21.5574 7.96878 21.5574 13.4819 18.157 16.8823L16.97 18.0562C16.0952 18.9149 14.96 20.0188 13.5642 21.3684C12.6919 22.2118 11.3081 22.2117 10.436 21.3682L6.9449 17.9723C6.50614 17.5415 6.13887 17.1782 5.84303 16.8823C2.44262 13.4819 2.44262 7.96878 5.84303 4.56837ZM17.0963 5.62903C14.2817 2.81441 9.71832 2.81441 6.90369 5.62903C4.08907 8.44366 4.08907 13.0071 6.90369 15.8217L8.39077 17.2891C9.20967 18.0906 10.2391 19.091 11.4788 20.29C11.7695 20.5711 12.2308 20.5712 12.5215 20.2901L15.9164 16.9886C16.3854 16.5283 16.7787 16.1393 17.0963 15.8217C19.911 13.0071 19.911 8.44366 17.0963 5.62903ZM12 7.99903C13.6577 7.99903 15.0016 9.34287 15.0016 11.0006C15.0016 12.6583 13.6577 14.0021 12 14.0021C10.3423 14.0021 8.99847 12.6583 8.99847 11.0006C8.99847 9.34287 10.3423 7.99903 12 7.99903ZM12 9.49903C11.1707 9.49903 10.4985 10.1713 10.4985 11.0006C10.4985 11.8299 11.1707 12.5021 12 12.5021C12.8293 12.5021 13.5016 11.8299 13.5016 11.0006C13.5016 10.1713 12.8293 9.49903 12 9.49903Z" fill="rgb(87, 89, 161)"/>
   </svg>
  )
}

const TopBar: FunctionComponent<TopBarProps> = (props): ReactElement => {
  const { weekStartDate, onWeekChange, onLocationChange, onDefaultLocationUpdate, activeLocation, defaultLocation, locale, locations } = props;
  const [inProgress , setInProgress] = useState<boolean>(false);
  const {id} = useSelector<RootState, Profile>(state => state.profile);

  const todayClickHandler = (): void => {
    onWeekChange(startOfWeek(new Date(), { locale }));
  };

  const locationChangeHandler = (event: ChangeEvent<{ value: unknown }>): void => {
    const value = event.target.value as string;
    onLocationChange(value);
  };

  const nextWeekClickHandler = (): void => {
    onWeekChange(addWeeks(weekStartDate, 1));
  };

  const previousWeekClickHandler = (): void => {
    onWeekChange(addWeeks(weekStartDate, -1));
  };

  const newEventClickHanldler = (): void => {
    const l = activeLocation!.locationId;
    if(l){
      navigateToEventPage(l);
    }
  };

  const isDefaultLocation = defaultLocation && defaultLocation.locationId === activeLocation?.locationId;

  const setAsDefaultLocation = async ():Promise<void>=>{
    if(isDefaultLocation){
      return;
    }
    setInProgress(true);
    try{
      let savedDefaultLocation:DefaultLocationResponse | null = null;

      if(!defaultLocation && activeLocation){
        const defaultLocationRecordId:string = await CalendarService.createDefaultLocation(id , activeLocation.locationId);
        savedDefaultLocation = {
          defaultLocationRecordId,
          locationName:activeLocation.locationName,
          locationId:activeLocation.locationId
        }
      }else{
        await CalendarService.updateDefaultLocation(defaultLocation!.defaultLocationRecordId , activeLocation!.locationId);
        savedDefaultLocation = {
          defaultLocationRecordId:defaultLocation!.defaultLocationRecordId,
          locationName:activeLocation!.locationName,
          locationId:activeLocation!.locationId
        }
      }
      onDefaultLocationUpdate(savedDefaultLocation);
    }catch(error){
      console.log(error);
    }finally{
      setInProgress(false);
    }
  }

  return (
    <div role='navigation'>
      <div className={classes.container}>
        <div style={{ position: 'relative' }}>
          <div>
            <div className={clsx(classes.command_bar, classes.root_48)} role='menubar'>
              <div role='group' className={classes.primary_set_52}>
                <div className={classes.item_53}>
                  <button type='button' onClick={todayClickHandler} className={clsx(classes.top_bar_content, classes.calendar_today_icon, classes.root_55)} tabIndex={0}>
                    <span className={classes.flex_container_41}>
                      <i role='presentation' aria-hidden='true' className={clsx(classes.icon_56, classes.icon_with_label)}>
                        <svg viewBox='-6 -6 32 32' role='presentation' focusable='false' className={classes.app_svg}>
                          <g className={classes.icons_default_fill}>
                            <path d='M14.5 3C15.8807 3 17 4.11929 17 5.5V14.5C17 15.8807 15.8807 17 14.5 17H11.5C11.5 17 11.5 16.6753 11.5 16.5V16H14.5C15.3284 16 16 15.3284 16 14.5V7H4V14.5C4 15.3284 4.67157 16 5.5 16H8.5V16.5C8.5 16.6753 8.5 17 8.5 17H5.5C4.11929 17 3 15.8807 3 14.5V5.5C3 4.11929 4.11929 3 5.5 3H14.5ZM14.5 4H5.5C4.67157 4 4 4.67157 4 5.5V6H16V5.5C16 4.67157 15.3284 4 14.5 4Z' />
                            <path d='M11 9C11 9.55228 10.5523 10 10 10C9.44772 10 9 9.55228 9 9C9 8.44772 9.44772 8 10 8C10.5523 8 11 8.44772 11 9Z' />
                            <path d='M11.8841 14.0701C11.7073 14.2822 11.392 14.3109 11.1799 14.1341L10.5 13.5675L10.5 17.5C10.5 17.7762 10.2761 18 9.99999 18C9.72385 18 9.49999 17.7762 9.49999 17.5V13.5675L8.82007 14.1341C8.60794 14.3109 8.29265 14.2822 8.11587 14.0701C7.93909 13.858 7.96775 13.5427 8.17989 13.3659L9.67989 12.1159C9.86531 11.9614 10.1347 11.9614 10.3201 12.1159L11.8201 13.3659C12.0322 13.5427 12.0609 13.858 11.8841 14.0701Z' />
                          </g>
                        </svg>
                      </i>
                      <span className={classes.textContainer_42}>
                        <span className={classes.label_57}>{Labels.Today}</span>
                      </span>
                    </span>
                  </button>
                </div>
                <PreviousWeekClickHandler onClick={previousWeekClickHandler} />
                <NextWeekClickHandler onClick={nextWeekClickHandler} />

                <div className={classes.item_53}>
                  <LocationSelect value={activeLocation?.locationId} onChange={locationChangeHandler}>
                    {locations.map(x => (
                      <MenuItem key={x.locationId} value={x.locationId} className={classes.menu_item}>
                        {x.locationName}
                      </MenuItem>
                    ))}
                  </LocationSelect>
                </div>
                <div className={classes.item_53}>
                  {
                    isDefaultLocation ? 
                    (
                      <div className={classes.icon_wrapper_default}>
                        <Tooltip title='Standort Standardmäßig' placement='top'>
                          <span>
                           <DefaultLocationIcon />
                          </span>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className={classes.icon_wrapper_active} onClick={setAsDefaultLocation}>
                        {
                          inProgress ? 
                          <CircularProgress size={15}/> :
                          <Tooltip title='Legen Sie den Standort als Standard fest' placement='top'>
                            <span>
                             <ChosenLocationIcon />
                            </span>
                          </Tooltip>
                        }
                      </div>
                    )
                  }
                </div>
                <div className={classes.item_53}>
                  <Picker date={weekStartDate} onDateChange={onWeekChange} />
                </div>
              </div>
              <div role='group' className={classes.secondary_set_163}>
                <div className={classes.item_69}>
                  <button type='button' onClick={newEventClickHanldler} className={clsx(classes.top_bar_content, classes.calendar_today_icon, classes.root_55)} tabIndex={0}>
                    <span className={classes.flex_container_41}>
                      <i role='presentation' aria-hidden='true' className={clsx(classes.icon_56, classes.icon_with_label)}>
                        <svg viewBox='-6 -6 32 32' role='presentation' className={classes.app_svg} focusable='false'>
                          <g className='icons-default-fill'>
                            <path
                              className='icons-unfilled'
                              d='M9.5 16.5C9.5 16.7761 9.72386 17 10 17C10.2761 17 10.5 16.7761 10.5 16.5V10.5H16.5C16.7761 10.5 17 10.2761 17 10C17 9.72386 16.7761 9.5 16.5 9.5H10.5V3.5C10.5 3.22386 10.2761 3 10 3C9.72386 3 9.5 3.22386 9.5 3.5V9.5H3.5C3.22386 9.5 3 9.72386 3 10C3 10.2761 3.22386 10.5 3.5 10.5H9.5V16.5Z'
                            ></path>
                            <path
                              className='icons-filled'
                              d='M9.25 16.25C9.25 16.6642 9.58579 17 10 17C10.4142 17 10.75 16.6642 10.75 16.25V10.75H16.25C16.6642 10.75 17 10.4142 17 10C17 9.58579 16.6642 9.25 16.25 9.25H10.75V3.75C10.75 3.33579 10.4142 3 10 3C9.58579 3 9.25 3.33579 9.25 3.75V9.25H3.75C3.33579 9.25 3 9.58579 3 10C3 10.4142 3.33579 10.75 3.75 10.75H9.25V16.25Z'
                            ></path>
                          </g>
                        </svg>
                      </i>
                      <span className={classes.textContainer_42}>
                        <span className={classes.label_57}>{Labels.NewTask}</span>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
