export const Processing = 'Verarbeiten...';
export const Save = 'Speichern';
export const Back = 'Zurück';
export const New = 'Neu';
export const VIN = 'Fahrzeug-Ident-Nr.';
export const Status = 'Zustand';
export const Model = 'Modell';
export const VehicleType = 'Fahrezeugtyp';
export const Construction = 'Aufbau';
export const Paintwork = 'Lackierung';
export const Undamaged = 'Unbeschädigt';
export const Damaged = 'Beschädigt';
export const Damage = 'Schäden';
export const RequiredField = 'Dieses Feld ist eine Pflichtangabe.';
export const NoDataAvailable = 'Keine Daten verfügbar.';
export const SearchVIN = 'Diese Ansicht Fahrzeug-Ident-Nr.';
export const Attachments = 'Anhänge';
export const NewTask = 'DEKRA-Termin';
export const Today = 'Heute';
export const AllDay = 'Ganztägig';
export const Close = 'Schließen';
export const Delete = 'Löschen';
export const RequestErrorLabel = 'Fehler bei der Anfrage!';
export const LogisticsError = 'Fehler beim Abfragen von Logistikdatensätzen';
export const Stop = 'Auftrag abschließen';
export const Undo = 'Rückgängig';
export const Inactive = 'Inaktiv';
export const Start = 'Auftrag starten';
export const Attention = 'Aufmerksamkeit !';
export const Decline = 'Ablehnen';
export const MissingСoordinates = 'Keine Koordinaten';
export const Create = 'Schaffen';
export const Select = 'Bitte auswählen';
export const CoordinatesError = 'Beim Abrufen der Fahrzeugkoordinaten ist ein Fehler aufgetreten';
export const QrCodeError = 'Auftrag nicht gefunden oder Datensatz nicht aktiv';
export const AllRecordsLoadedMessage = 'Das sind alles Rekorde !';
export const Logout = 'Ausloggen';
export const Login = 'Anmeldung';
export const BackHome = 'Zurück zuhause';
export const CustomerOnSite = 'Customer on site';
export const KEINE_DATEN = 'Keine Daten';

export const USER_CREDENTIALS_REQUEST_FAILED = 'User information request failed!';
export const USER_AUTHENTICATION_FAILED = 'User authentication failed!';

export const DENIED_GEOLOCATION = 'User denied Geolocation';
export const GEOLOCATION_UNDETERMINED = 'Keine Geolokalisierung gefunden.';