import { FunctionComponent, ReactElement } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Damage } from "../../../services/damage-service";
import ImageCell from "../ImageCell";

interface TableRowWrapperProps extends Damage {
  vin: string;
  showRepairType: boolean;
}

const TableRowWrapper: FunctionComponent<TableRowWrapperProps> = (
  props
): ReactElement => {
  const { component, damage, description, vin, id, imageId, purchaseOrder, showRepairType, repairType } = props;
  return (
    <TableRow>
      <ImageCell id={id} vin={vin} imageName={damage} imageId={imageId} purchaseOrder={purchaseOrder}  />
      <TableCell align="center">{component}</TableCell>
      <TableCell align="right">{damage}</TableCell>
      <TableCell align="right">{description}</TableCell>
      {showRepairType && <TableCell align="right">{repairType}</TableCell>}
    </TableRow>
  );
};

export default TableRowWrapper;
