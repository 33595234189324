import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import { createTheme, CssBaseline } from '@material-ui/core';

import "react-toastify/dist/ReactToastify.css";
import './index.scss';
import Routes from './routes';
import store from './store';
import Backdrop from './components/backdrop';
import reduxStore from './store';

import { forgotPasswordRequest, loginRequest, msalInstance, resetPasswordPolicy, signInPolicy } from './services/auth-service';
import { MsalProvider } from '@azure/msal-react';
import { head } from 'lodash';
import { AuthorizationUrlRequest, EventType, InteractionType ,AuthenticationResult } from '@azure/msal-browser';
import { fetchProfileSucceeded, setProfileLoading } from './actions/profile';
import userService, { UserInfo } from './services/user-service';
import history from './history';
import * as Labels from './unit/labels';
import { ThemeProvider } from '@material-ui/core';
import { AxiosError } from 'axios';
import { QueryClientProvider, QueryClient } from 'react-query';
import './services/calendar-service';


const redirectToAuthErrorPage = (message: string = '', details: string = ''): void => {
  history.push('/authentication-error', { message, details });
};

const redirectToErrorPage = (message: string, details:{code:number | undefined}):void => {
  history.push(`/error?message=${message}`,{...details});
}

msalInstance.addEventCallback((event: any) => {
  const accountInfo = head(msalInstance.getAllAccounts()) || null;
  msalInstance.setActiveAccount(accountInfo);
  
  if (event.eventType === EventType.HANDLE_REDIRECT_END && accountInfo) {

    msalInstance.handleRedirectPromise()
      .then( (result:AuthenticationResult | null) =>{
        const account = msalInstance.getActiveAccount()
        if(!account || !result){
          msalInstance.loginRedirect(loginRequest as AuthorizationUrlRequest);
          return;
        }

        userService
       .fetchRoles()
       .then((x: UserInfo) =>
         reduxStore.dispatch(
           fetchProfileSucceeded(
             x.roles.map(x => x.reparturweg),
             x.logo ?? '',
             x.fullname,
             x.id
           )
         )
       )
       .catch((x: AxiosError) => {
         reduxStore.dispatch(setProfileLoading(false));
         redirectToErrorPage(x.message || Labels.USER_CREDENTIALS_REQUEST_FAILED, {code:x.response?.status});
       });

      })
      .catch((err)=>{
        console.log(err)
        redirectToErrorPage(err?.message ?? Labels.USER_AUTHENTICATION_FAILED, {code:undefined})
      })
  }

  if (event.eventType === EventType.LOGIN_SUCCESS) {
    if (event.interactionType === InteractionType.Redirect) {
      if (event.payload.idTokenClaims['tfp'] === signInPolicy) {
        let account = event.payload.account;
        if (!account) {
          // event.payload.account is undefined, so we take first account from all available
          // there is no case when user can have several accounts
          account = head(msalInstance.getAllAccounts());
        }
        msalInstance.setActiveAccount(account);
      }
      if (event.payload.idTokenClaims['tfp'] === resetPasswordPolicy) {
        msalInstance.logoutRedirect();
      }
    }
  }

  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    if (event.error && event.error.errorMessage.indexOf('AADB2C90077') > -1) {
      msalInstance.loginRedirect(loginRequest as AuthorizationUrlRequest);
      return;
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    //TODO: error code to constant
    if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
      if (event.interactionType === InteractionType.Redirect) {
        msalInstance.loginRedirect(forgotPasswordRequest as AuthorizationUrlRequest);
        return;
      }
    }

    // TODO: error code to constant
    if (event.error && event.error.errorMessage.indexOf('AADB2C90091') > -1) {
      if (event.interactionType === InteractionType.Redirect) {
        msalInstance.loginRedirect(loginRequest as AuthorizationUrlRequest);
        return;
      }
    }

    redirectToAuthErrorPage(event.error.errorMessage);
  }
});

const theme = createTheme();
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
              <Backdrop />
              <CssBaseline />
              <MsalProvider instance={msalInstance}>
                <Routes />
              </MsalProvider>
          </Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'development') {
  const reportWebVitals = require('./reportWebVitals').default;
  reportWebVitals(console.log);
}
