import { useState, useEffect, FunctionComponent, ReactElement , ReactSVGElement, useRef, RefObject } from 'react';
import {useOutsideClick} from '../../../hooks';
import clsx from 'clsx';
import classes from './tim-picker.module.scss';

interface SvgProps{
    styles:string
}

const ResetIcon:FunctionComponent=():ReactElement<ReactSVGElement>=>{
   return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5Z" fill="#D1050C"/>
      <path d="M6.3483 6.43145L6.38729 6.38729C6.55627 6.21832 6.82216 6.20532 7.00605 6.3483L7.05021 6.38729L10 9.33672L12.9498 6.38729C13.1329 6.20424 13.4296 6.20424 13.6127 6.38729C13.7958 6.57035 13.7958 6.86715 13.6127 7.05021L10.6633 10L13.6127 12.9498C13.7817 13.1188 13.7947 13.3847 13.6517 13.5685L13.6127 13.6127C13.4437 13.7817 13.1778 13.7947 12.994 13.6517L12.9498 13.6127L10 10.6633L7.05021 13.6127C6.86715 13.7958 6.57035 13.7958 6.38729 13.6127C6.20424 13.4296 6.20424 13.1329 6.38729 12.9498L9.33672 10L6.38729 7.05021C6.21832 6.88123 6.20532 6.61534 6.3483 6.43145Z" fill="#D1050C" stroke="#D1050C" stroke-width="0.5"/>
    </svg>
   )
}

const OpenCloseIcon:FunctionComponent<SvgProps>= ({styles}):ReactElement<ReactSVGElement>=>{
    return (
        <svg className={styles} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path  d="M2.86128 10.4712C3.12163 10.7316 3.54374 10.7316 3.80409 10.4712L7.99935 6.27598L12.1946 10.4712C12.455 10.7316 12.8771 10.7316 13.1374 10.4712C13.3978 10.2109 13.3978 9.78878 13.1374 9.52843L8.47075 4.86177C8.2104 4.60142 7.78829 4.60142 7.52794 4.86177L2.86128 9.52843C2.60093 9.78878 2.60093 10.2109 2.86128 10.4712Z" fill="black"/>
        </svg>
    )
}


interface TimePickerProps{
    timeList:string[];
    onTimeSelectHandler(name:string , value:string):void;
    timePickersStateHandler(day:string, isOpen:boolean):void;
    fromValue:string;
    toValue:string;
    fromDay:string;
    toDay:string;
    isOpen:boolean;
}

const TimePickerStyled:FunctionComponent<TimePickerProps> = (props):ReactElement=>{
    const {timeList , onTimeSelectHandler , timePickersStateHandler, fromValue, toValue, fromDay, toDay, isOpen} = props;
    const defaultValue = '---';
    const [open, setOpen] = useState<boolean>(false);
    const [fromTime , setFromTime] = useState<string>(fromValue);
    const [toTime , setToTime] = useState<string>(toValue);
    const index = fromDay.indexOf('From');
    const currentDay = fromDay.slice(0 , index);
    const timePickerRef:RefObject<HTMLDivElement> | null  = useRef<HTMLDivElement | null>(null);

    const timePickerVisibilityHandler = (day:string, open:boolean)=>{
        timePickersStateHandler(day, open);
    }

    useOutsideClick(timePickerRef, currentDay, timePickerVisibilityHandler);

    useEffect(()=>{
        setOpen(isOpen);
    }, [isOpen])


    const setFromTimeHandler = (time:string):(()=>void)=>{
        return ():void=>{
            if(time === fromTime){
                return;
            }
            onTimeSelectHandler(fromDay, time);
        }
    }

    const setToTimeHandler = (time:string):(()=>void)=>{
        return ():void=>{
            if(time === toTime){
                return;
            }
            onTimeSelectHandler(toDay, time);
        }
    }


    const resetTimeHandler = ()=>{
      if(open){
        timePickersStateHandler(currentDay, !open);
      }
      onTimeSelectHandler(fromDay, defaultValue);
      onTimeSelectHandler(toDay, defaultValue);
    }

    useEffect(()=>{
       setFromTime(fromValue);
    }, [fromValue])

    useEffect(()=>{
        setToTime(toValue);
     }, [toValue])

    return (
        <div ref={timePickerRef} className={clsx(classes.time_container , {[classes.open_state]:open, [classes.close_state]:!open})}>
            <div className={classes.time_display} onClick={()=>timePickerVisibilityHandler(currentDay, !open)}>{`${fromTime} - ${toTime}`}</div>
            <div
                className={clsx(classes.close_icon_container ,
                 {
                  [classes.close_container]:!open,
                  [classes.open_container]:open
                 }
                )}
                onClick={()=>timePickerVisibilityHandler(currentDay, !open)}
            >
            < OpenCloseIcon styles={classes.close_icon}/>
            </div>
            <div className={classes.reset} onClick={resetTimeHandler} role='button' tabIndex={0} aria-label={'reset time'} title={`reset time`}>
              <ResetIcon />
            </div>
            <div className={clsx(classes.time_lists_wrapper , {
                [classes.visible]:open,
                [classes.invisible]:!open,
                [classes.padding_0]:!open,
                }
              )}
            >
                <div className={clsx(classes.time_lists_container, {[classes.visible]:open, [classes.invisible]:!open})}>
                <div className={clsx(classes.time_box , {[classes.visible]:open, [classes.invisible]:!open})}>
                <div className={clsx(classes.title , {[classes.title_invisible]:!open})}>From</div>
                    <ul className={clsx({}, {[classes.visible]:open, [classes.invisible]:!open})}>
                    {
                    timeList.map( t =>
                        <li className={clsx({}, {[classes.selected]: t === fromTime})} onClick={setFromTimeHandler(t)} key={`from${t}`} role='button' tabIndex={0} aria-label={`from ${t}`} title={`from ${t}`}>
                        {t}
                        </li>
                        )
                    }
                    </ul>
                </div>
                <div className={clsx(classes.time_box , {[classes.visible]:open, [classes.invisible]:!open})}>
                <div className={clsx(classes.title , {[classes.title_invisible]:!open})}>To</div>
                    <ul className={clsx({}, {[classes.visible]:open, [classes.invisible]:!open})}>
                    {
                    timeList.map( t =>
                        <li className={clsx({}, {[classes.selected]: t === toTime})} onClick={setToTimeHandler(t)} key={`to${t}`} role='button' tabIndex={0} aria-label={`to ${t}`} title={`to ${t}`}>
                        {t}
                        </li>
                        )
                    }
                    </ul>
                </div>
                </div>
            </div>
        </div>
    )
}

export default TimePickerStyled;