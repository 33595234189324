import React, { FunctionComponent, ReactElement } from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { format } from "date-fns";
import LaunchIcon from "@material-ui/icons/Launch";
import sharepointService from "../../services/sharepoint-service";
import ImageIcon from "@material-ui/icons/Image";
import { useQuery } from "react-query";
import { Skeleton } from "@material-ui/lab";
import { isImageExtension, isPdfExtension } from "../../unit/utils";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionIcon from "@material-ui/icons/Description";

interface DocumentItemProps {
  name: string;
  createdOn: string;
  id: string;
}

const DocumentItem: FunctionComponent<DocumentItemProps> = (
  props
): ReactElement => {
  const { name, id, createdOn } = props;
  const { isLoading, error, data } = useQuery(id, () =>
    sharepointService.fetchFilePreview(id, "lueg_auftrag_an")
  );

  const renderIcon = (): ReactElement => {
    if (isLoading) {
      return <Skeleton variant="circle" width={40} height={40} />;
    }
    if (error || !data || !data.body) {
      if (isPdfExtension(name)) {
        return <PictureAsPdfIcon />;
      }
      if (isImageExtension(name)) {
        return <ImageIcon />;
      }

      return <DescriptionIcon />;
    }

    return <img src={`data:image/png;base64, ${data.body}`} alt="preview" />;
  };

  const clickHandler = async (): Promise<void> => {
    await sharepointService.browseFile(id, name);
  };

  return (
    <ListItem onClick={clickHandler} button>
      <ListItemAvatar>
        <Avatar>{renderIcon()}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={format(new Date(createdOn), "dd.MM.yyy")}
      />
    </ListItem>
  );
};

export default DocumentItem;
