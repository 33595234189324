import {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
  MouseEvent,
  ChangeEvent,
} from "react";
import {
  getDefaultSmartTableResult,
  Pagination,
  Search,
  SmartTableParam,
  SmartTableResult,
} from "../../models/smart-table";
import { Damage } from "../../services/damage-service";
import damageService from "../../services/damage-service";
import LoadingRow from "../../components/LoadingRow";
import ErrorRow from "../../components/ErrorRow";
import {
  TableContainer,
  Table,
  TableBody,
  makeStyles,
  TablePagination
} from "@material-ui/core";
import { cloneDeep } from "lodash";
import VehicleDamagesTableHeader from "./VehicleDamagesTableHeader";
import TableRow from "./VehicleDamagesTable/TableRowWrapper";

interface VehicleDamagesTableProps {
  vehicleId?: string;
  orderId?: string;
  vin: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  toolbar: {
    paddingLeft: "0",
    display: "flex",
    justifyContent: "space-between",
  },
});

const colSpan = 4;

const VehicleDamagesTable: FunctionComponent<VehicleDamagesTableProps> = (
  props
): ReactElement => {
  const { vehicleId, orderId, vin } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [tableParam, setTableParam] = useState<SmartTableParam>(
    new SmartTableParam(new Pagination(1, 20), new Search({ vehicleId, orderId }))
  );

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [tableResult, setTableResult] = useState<
    SmartTableResult<Damage>
  >(getDefaultSmartTableResult<Damage>());

  // TODO: use query
  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        setErrorMessage("");
        setTableResult(getDefaultSmartTableResult());
        const result = await damageService.getDamages(tableParam);
        setTableResult(result);
      } catch (err:any) {
        console.log(err)
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    };
    load();

    window.addEventListener("refresh-vehicle-table", load);

    return () => {
      window.removeEventListener("refresh-vehicle-table", load);
    }
  }, [tableParam, setTableResult, setErrorMessage, setLoading]);

  const pageChangeHandler = (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number
  ): void => {
    setTableParam((tableParam) => {
      const param = cloneDeep(tableParam);
      param.pagination.start = page + 1;
      return param;
    });
  };

  const rowsPerPageChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const number = event.target.value;
    setTableParam((tableParam) => {
      const param = cloneDeep(tableParam);
      param.pagination.number = +number;
      return param;
    });
  };

  return (
    <TableContainer className="container">
      <Table className={classes.table} size="small">
        <VehicleDamagesTableHeader showRepairType = {!orderId} />
        <TableBody>
          <LoadingRow loading={loading} tableCellProps={{ colSpan }} />
          <ErrorRow message={errorMessage} tableCellProps={{ colSpan }} />
          {tableResult.items.map((row:Damage) => (<TableRow key={row.id}  vin={vin} {...row} id={row.id} showRepairType={!orderId} />))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 25, 30]}
        component="div"
        count={tableResult.totalRecord}
        rowsPerPage={tableParam.pagination.number}
        page={tableParam.pagination.start -1}
        onPageChange={pageChangeHandler}
        onRowsPerPageChange={rowsPerPageChangeHandler}
      />
    </TableContainer>
  );
};

export default VehicleDamagesTable;
