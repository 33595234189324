import axios from "axios";

import authorizationService from "./auth-service";
import { SHAREPOINT_API_URL, IDENTITY_SERVER_URL } from "../urls";

interface PreviewResponse {
  id: string;
  body: string;
}

export interface UploadFileModel {
  name: string;
  bodyBase64: string;
  availableForCustomer: boolean;
  documentSigned: boolean;
  canBeDeletedByCustomer: boolean;
  requireSignature: boolean;
  createdBy: string;
  modifiedBy: string;
  comment: string;
  documentType: string;
  [key: string]: any;
}

export interface UploadFileCommand {
  folderName: string;
  fileToUpload: UploadFileModel;
  entityName: string;
}

export interface SharepointFile {
  id: string;
  relativeUrl: string;
  name: string;
  availableForCustomer: boolean;
  documentSigned: boolean;
  canBeDeletedByCustomer: boolean;
  requireSignature: boolean;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  comment: string;
  documentType: string;
  [key: string]: boolean | string;
}

// TODO: Interfaces and test this method
// TODO: Documentation
class SharepointService {
  /**
   * Returns list of documents inside of the sharepoint's folder
   * @param library - name of folder(library), name of folder is stored inside of attribute (Example: ANP2021362L035-001)
   * @param entityName - entity logical name of connected entity(Example: lueg_auftrag_an)
   */
  public async fetchDocuments(
    library: string,
    entityName: string
  ): Promise<any[]> {
    const authorizationHeaderValue = await authorizationService.getAuthHeader();
    const response = await axios({
      method: "GET",
      url: `${SHAREPOINT_API_URL}/api/sharepoint-library/${library}/${entityName}`,
      headers: {
        Authorization: authorizationHeaderValue,
      },
    });

    return response.data;
  }



  /**
   * Uploads sharepoint document do specified folder
   * @param file -  sharepoint document
   */
  public async uploadDocument(file: UploadFileCommand): Promise<SharepointFile> {
    const authorizationHeaderValue = await authorizationService.getAuthHeader();
    const response = await axios.post(`${SHAREPOINT_API_URL}/api/sharepoint-library/upload-file`, file, {
      headers: {
        Authorization: authorizationHeaderValue
      }
    });

    return response.data;
  }

  /*
   * Opens protected with authentication file in new browser tab
   * @param id - file id which will be opned
   * @param name - file name.  used as tab name in browser
   */
  public async browseFile(id: string, name: string): Promise<void> {
    const token = await this.acquireFileToken(id);
    window.open(
      `${SHAREPOINT_API_URL}/api/sharepoint-library/file/${token}/${name.replace(/[^a-zA-Z_\s]/g,"")}`,
      "_blank"
    );
  }

  /**
   * Gets short living jwt-token which used for accessing sharepoint files
   * @param id - sharepoint file id
   * @returns short living file's jwt-token for accessing sharepoint file
   */
  private async acquireFileToken(id: string): Promise<string> {
    const authorizationHeaderValue = await authorizationService.getAuthHeader();
    const response = await axios.get(
      `${IDENTITY_SERVER_URL}/api/token?id=${id}`,
      {
        headers: {
          Authorization: authorizationHeaderValue,
        },
      }
    );

    return response.data;
  }

  /**
   * Returns base64 representations of small file. For pdf, words, exels, it's image of first page
   * For images it's just small sized image
   * @param id - file id
   * @param entityLogicalName - entity logical name of folder
   * @returns {PreviewResponse} image base64 representation and file id
   */
  public async fetchFilePreview(
    id: string,
    entityLogicalName: string
  ): Promise<PreviewResponse> {
    const authorizationHeaderValue = await authorizationService.getAuthHeader();
    const response = await axios.get(
      `${SHAREPOINT_API_URL}/api/sharepoint-library/preview/${id}/${entityLogicalName}`,
      {
        headers: {
          Authorization: authorizationHeaderValue,
        },
      }
    );

    return response.data;
  }
}

const sharepointService = new SharepointService();
export default sharepointService;
