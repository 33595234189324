import { AxiosError } from "axios";
import { imageExtensions } from './constants';
import { timeStringToMinutes , Task } from 'calendar/dist';
import { format, getHours, getMinutes, getSeconds } from "date-fns";
import deLocale from "date-fns/locale/de";
import {RampWorkingHoursResponse , RampWorkingHoursToString, RampWorkingHoursToDecimal} from '../services/dekra-locations-service';
import { CreateTaskCommand } from "../services/calendar-service";
import { FormState } from "../routes/dekra-calendar-event/event";

export const isImageExtension = (name: string) => {
  const ext = getExtension(name);
  return imageExtensions.indexOf(ext) > -1;
};

export const getExtension = (name?: string) => {
  if (!name) {
    return "";
  }

  return name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2).trim();
};

export const isPdfExtension = (name: string): boolean => {
  const regEx = /\.pdf\s*$/gi;
  return regEx.test(name);
};

export const formUrlEncode = (obj: { [key: string]: any }): string => {
  const keys = Object.keys(obj);
  return keys
    .reduce<string[]>((prev: string[], curr: string) => {
      prev.push(`${curr}=${obj[curr].toString()}`);
      return prev;
    }, [])
    .join("&");
};

export const zeroPrefix = (val: number): string => `0${val}`.slice(-2);

export const getDuration = (from: Date | null, to: Date | null, isWholeDay: boolean): string => {
  if (isWholeDay) {
    return '1d';
  }

  if (!from || !to) {
    return '0m';
  }

  var diffMinutes = (to.getTime() - from.getTime()) / 60000;

  const hours = Math.floor(diffMinutes / 60);
  const minutes = diffMinutes % 60;

  if (hours === 0) {
    return `${minutes}m`;
  }

  if (minutes === 0) {
    return `${hours}h`;
  }

  return `${zeroPrefix(hours)}: ${zeroPrefix(minutes)}h`;
};

export const timeDifference = (from: string, to: string): number => {
  const f = timeStringToMinutes(from);
  const t = timeStringToMinutes(to);
  return t - f;
};

export const timeStringToMilliseconds = (val: string): number => {
  const [hours, minutes] = val.split(':');
  const h = parseInt(hours);
  const m = parseInt(minutes);
  return (h * 60 * 60 * 1000) + (m * 60 * 1000);
};

interface Options {
  year: 'numeric' | '2-digit' | undefined,
  month: 'numeric' | '2-digit' | undefined,
  day: 'numeric' | '2-digit' | undefined,
}

export const convertToDateFormat = (date:string | null): string=>{
  if(!date){
     return '---'
  }
  const newDate = new Date(date)
  const options:Options = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  }
  const convertedDate = newDate.toLocaleDateString('de-DE', options);
  return convertedDate;
}


export const getErrorMessage = (error: any): any => {
  let err:any = '';
  if(error?.isAxiosError){
    err = JSON.stringify((error as AxiosError).response?.data)
  }else{
    err = JSON.stringify(error);
  }
  return err;
};

export const extractErrorMessage =(error:any):{errorMessage:string, statusCode:string}=>{
  let err = JSON.parse(getErrorMessage(error));
  let errorMessage = '';
  let statusCode = err?.status ?? '';
  if (err?.errorMessage) {
      errorMessage = err.errorMessage;
  }
  else if(err?.errors) {
      errorMessage = (Object.values(err.errors) as string[][]).map((x: string[]) => x.join(';')).join(';');
  }
  else if(err?.title){
      errorMessage = err.title
  }
  else if(error?.message){
    const errResponse= JSON.parse(JSON.stringify(error.response));
    statusCode = errResponse.status;
    errorMessage = error.message;
  }
  return {
    errorMessage,
    statusCode
  };
}

export interface Base64File {
  name: string;
  body: string;
}

export const readFileAsBase64Async = (file: File): Promise<Base64File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.addEventListener('load', () => {
      resolve({
        name: file.name,
        body: reader.result as string
      });
    });
  });
};

export const removeBase64Prefix = (str: string): string => {
  const values = str.split(',');
  return values.length === 2 ? values[1] : str;
};

export const getCorrectDate = (date:string):string=>{
  const validateTime = (value:number):string=>{
    if(value < 10){
       return '0' + value
    }
    return value + ''
  }

  const liberkeeUpdatedDate =  new Date(date)
  const hours = getHours(liberkeeUpdatedDate)
  const minutes = getMinutes(liberkeeUpdatedDate)
  const seconds = getSeconds(liberkeeUpdatedDate)
  const time = `${validateTime(hours)}:${validateTime(minutes)}:${validateTime(seconds)}`

  return `${format(liberkeeUpdatedDate, "dd.MM.yyyy", { locale: deLocale })} / ${time}`
}

export const decimalToTimeString = (val: number): string => {
  const hours = Math.floor(val);
  const minutes = val - hours;

  const h = `0${hours}`.slice(-2);
  const m = minutes === 0.5 ? '30' : '00';

  return `${h}:${m}`;
};

export interface RampTimeCodeSchema{
  [key:string]:{
    stringValue:string,
    decimalValue:string
  }
}

export const mapToObjectFormat = ()=>{
  const result: RampTimeCodeSchema = {};
  let hour = 0;

  while (hour < 24) {
    let decimalValue = '52897';
    const timeToString = decimalToTimeString(hour);
    const stringValue = timeToString.split(':').join('');
    decimalValue = decimalValue + stringValue;
    result[decimalValue] = {
      decimalValue,
      stringValue:timeToString
    }
    hour = hour + 0.5;
  }
  return result;
}

export type AsDecimalValue = Omit<RampWorkingHoursResponse ,'status' | 'rampId' | 'rampName' >;
export type AsStringValue = Omit<RampWorkingHoursToString , 'status' | 'rampId' | 'rampName'>;
export type Picked = Pick<AsStringValue, 'saturdayFrom' | 'saturdayTo' | 'sundayFrom' | 'sundayTo'>;


export const mapDecimalInStringValue = (rest:AsDecimalValue, keys:string[])=>{
  const mappedValues = mapToObjectFormat();
  return {
    mappedValues,
    result:keys.reduce( (previousValue:AsStringValue | {}, currentValue:string):AsStringValue | {}=>{
      const key = currentValue as keyof AsDecimalValue;
      const isDecimal = rest[key];
      if(isDecimal){
        (previousValue as AsStringValue)[key] = mappedValues[isDecimal.toString()].stringValue
      }else{
       (previousValue as AsStringValue)[key as keyof Picked] = isDecimal as null
      }
      return previousValue as AsStringValue;
    }, {})
  }
}


export const transform =(schema:RampTimeCodeSchema):RampTimeCodeSchema=>{
  let result:RampTimeCodeSchema = {};

  const toNewSchema = Object.values(schema).map( (item:{stringValue:string, decimalValue:string}):RampTimeCodeSchema => {
   const {stringValue , decimalValue} = item;
   let newItem:RampTimeCodeSchema = {};
   newItem[stringValue] = {
      stringValue,
      decimalValue
   }
   return newItem;
  });

  toNewSchema.forEach( each =>{
      result = {...result , ...each};
  })
  return {
          ...result,
          '---':{
              stringValue:'---',
              decimalValue:'---'
          }
   }
}

const validateValue = (val:string | null):number | null=>{
  if(!val || val === '---'){
    return null;
  }
  return Number(val);
}

export const convertToDecimalFormat = (form:Omit<RampWorkingHoursToString , 'status'>, schema:RampTimeCodeSchema):RampWorkingHoursToDecimal=>{

  return {
    Id:form?.rampId ?? '',
    Name:form.rampName,
    LocationId:form.locationId,
    MondayFrom:form.mondayFrom ? validateValue(schema[form.mondayFrom].decimalValue) : null,
    MondayTo:form.mondayTo ? validateValue(schema[form.mondayTo].decimalValue) : null,
    TuesdayFrom: form.tuesdayFrom ? validateValue(schema[form.tuesdayFrom].decimalValue) : null,
    TuesdayTo:form.tuesdayTo ? validateValue(schema[form.tuesdayTo].decimalValue) : null,
    WednesdayFrom:form.wednesdayFrom ? validateValue(schema[form.wednesdayFrom].decimalValue): null,
    WednesdayTo:form.wednesdayTo ? validateValue(schema[form.wednesdayTo].decimalValue) : null,
    ThursdayFrom:form.thursdayFrom ? validateValue(schema[form.thursdayFrom].decimalValue) : null,
    ThursdayTo:form.thursdayTo ? validateValue(schema[form.thursdayTo].decimalValue) : null,
    FridayFrom:form.fridayFrom ? validateValue(schema[form.fridayFrom].decimalValue) : null,
    FridayTo:form.fridayTo ? validateValue(schema[form.fridayTo].decimalValue) : null,
    SaturdayFrom: form.saturdayFrom ? validateValue(schema[form.saturdayFrom].decimalValue) : null,
    SaturdayTo:form.saturdayTo ? validateValue(schema[form.saturdayTo].decimalValue) : null,
    SundayFrom:form.sundayFrom ? validateValue(schema[form.sundayFrom].decimalValue) : null,
    SundayTo :form.sundayTo ? validateValue(schema[form.sundayTo].decimalValue) : null,
  }

}


const convertToReadableNumber = (date:string):string=>{
  let hours = getHours(new Date(date));
  let minutes = getMinutes(new Date(date));

  return `${zeroPrefix(hours)}:${zeroPrefix(minutes)}`;
}

const getDate = (input:{from:string, to:string}):Date=>{
   const {from , to} = input

   const resetTimeInDate = (date:string):Date=>{
     let clearDate = new Date(date);
     clearDate.setHours(0);
     clearDate.setMinutes(0);
     clearDate.setSeconds(0);
     return clearDate;
   }

   if(from){
    return resetTimeInDate(from);
   }

   return resetTimeInDate(to!);
}

interface ExpectableResult{
  from:string,
  to:string,
}

export const expandWithFields = (from:string , to:string)=>{
  const result = [from , to].reduce((compound:ExpectableResult , currentValue:string, index:number):ExpectableResult=>{
     if(!(index % 2)){
      compound.from = convertToReadableNumber(currentValue)
     }else{
      compound.to = convertToReadableNumber(currentValue)
     }
      return compound
  }, {from:'' , to:''})

  return {
    ...result,
    date:getDate({from , to})
  }
}


export type DragAndDropUpdateCommand = Omit<CreateTaskCommand, 'kennzeichen'>;

export const dragAndDropUpdateTaskHandler = (task:Task, startAndEndPoints:{from:string , to:string}):DragAndDropUpdateCommand=>{

  let updatedTask:DragAndDropUpdateCommand = {
    title: task.title,
    locationId: task.locationId,
    from: startAndEndPoints.from,
    to: startAndEndPoints.to,
    note: task.note,
    isWholeDay: task.isWholeDay,
    rampId:task.rampId,
    customerOnSite:task.customerOnSite,
  }

  if(task?.serviceId){
    updatedTask.serviceId = task.serviceId
  }

  return updatedTask;
}

export const createOrEditTask = (form:FormState):CreateTaskCommand=>{
  const {from , to ,isWholeDay , date, rampFrom , rampTo , rampId, serviceId , calculatedEndTime, title, locationId, note, mark, customerOnSite} = form;
  const dateFrom = new Date(date);
  const dateTo = new Date(date);

  if(isWholeDay){
    dateFrom.setHours(rampFrom!.getHours(), rampFrom!.getMinutes());
    dateTo.setHours(rampTo!.getHours(), rampTo!.getMinutes());
  }
  else if(serviceId && calculatedEndTime){
    dateFrom.setHours(from.getHours(), from.getMinutes());
    dateTo.setHours(calculatedEndTime.getHours(), calculatedEndTime.getMinutes());
  }else{
    dateFrom.setHours(from.getHours(), from.getMinutes());
    dateTo.setHours(to.getHours(), to.getMinutes());
  }

  let Task:CreateTaskCommand = {
    title:title || '',
    locationId,
    from: format(dateFrom, `yyyy-MM-dd'T'HH:mm:ss`),
    to: format(dateTo, `yyyy-MM-dd'T'HH:mm:ss`),
    note,
    isWholeDay,
    rampId:!!rampId ? rampId : null,
    kennzeichen:mark || null,
    serviceId:serviceId || null,
    customerOnSite,
  }

  return Task;
}

