import { FunctionComponent, ReactElement } from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import AuthenticationErrorImage from '../assets/svg/auth-error-icon.svg';
import {LogoutBtn} from '../components/LogoutBtn';

interface LocationState {
  message: string;
  details: string;
}

const useStyles = makeStyles({
  header: {
    fontSize: '22px'
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  box:{
    display: 'flex'
  },
  message:{
    maxWidth: 300
  },
  image_container:{
    width: 200,
    height:200,
    "& img":{
      width: '100%', 
      height: '100%'
    }
  },
  divider:{
    height: 10
  }
});

const AuthenticationError: FunctionComponent = (): ReactElement => {
  const classes = useStyles();
  const { state } = useLocation<LocationState>();

  return (
    <Container className={classes.paper}>
      <div className={classes.box} >
        <section className={classes.message}>
          <Typography align='center' variant='h1' className={classes.header} paragraph >
            Authentication Error
         </Typography>
         <Typography paragraph><strong>Message:</strong>{state?.message}</Typography>
         <Typography><strong>Details:</strong>{state?.details}</Typography>
         <div className={classes.divider} />
         <LogoutBtn />
        </section>
        <section className={classes.image_container}>
          <img src={AuthenticationErrorImage} alt=''/>
        </section>
      </div>
    </Container>
  );
};

export default AuthenticationError;
