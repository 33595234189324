import { FunctionComponent, ReactElement } from "react";
import sharepointService from "../../services/sharepoint-service";
import {
  CircularProgress,
  List,
  Typography,
} from "@material-ui/core";
import * as Labels from "../../unit/labels";
import { useQuery } from "react-query";
import { Alert, AlertTitle } from "@material-ui/lab";
import DocumentItem from './DocumentItem';


interface DocumentsProps {
  library: string;
  entityName: string;
}

const Documents: FunctionComponent<DocumentsProps> = (props): ReactElement => {
  const { library, entityName } = props;
  const { data, isLoading, error } = useQuery(
    "documents",
    () => sharepointService.fetchDocuments(library, entityName),
    {
      refetchOnWindowFocus: false
    }
  );

  if (isLoading) {
    return (
      <div className="t-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>{Labels.RequestErrorLabel}</AlertTitle>
        {(error as any).message}
      </Alert>
    );
  }

  if (!data || !data.length) {
    return (
      <Typography align="center" variant="h6">
        {Labels.NoDataAvailable}
      </Typography>
    );
  }

  return (
    <List>
      {data.map((item) => (
        <DocumentItem key={item.id} createdOn={item.createdOn} id={item.id} name={item.name} />
      ))}
    </List>
  );
};

export default Documents;
