import {useState, FunctionComponent, ReactElement } from "react";
import {LinearProgress} from "@material-ui/core";
import { useParams , useLocation} from "react-router-dom";
import {RampUpdateForm} from './ramp-update-form';
import {useQuery} from 'react-query';
import DekraLocationsService , {RampWorkingHoursResponse, RampWorkingHoursToString, RampWorkingHoursToDecimal} from '../../services/dekra-locations-service';
import {mapDecimalInStringValue , RampTimeCodeSchema , extractErrorMessage} from '../../unit/utils';
import { useErrorPage } from "../../hooks";

interface RampParams{
    id:string
}

const useSearhParams = ():string=>{
    const {search, pathname} = useLocation();
    const url = new URL(`https://example${pathname}${search}`)
    return url.searchParams.get('locId') ?? '';
}


const RampDetails:FunctionComponent= ():ReactElement=>{
    const {id} = useParams<RampParams>();
    const [rampState , setRampState] = useState<RampWorkingHoursToString | null>(null);
    const [rampTimeCodeSchema , setRampTimeCodeSchema] = useState<RampTimeCodeSchema | null>(null);
    const [updateRampInProgress , setUpdateRampInProgress] = useState<boolean>(false);
    const locationId = useSearhParams();
    const navigate = useErrorPage();

    const {isLoading , isFetching , refetch} = useQuery(
        ['get-ramp-by-id', id] ,
        async()=>{
          return DekraLocationsService.getRampById(id);
        },
        {
          onSuccess:(response)=>{
            if(response){
             const {rampState , mappedValues} = response;
             setRampState((rampState as RampWorkingHoursToString));
             setRampTimeCodeSchema(mappedValues);
            }
          },
          onError:(error:any)=>{
            const message = extractErrorMessage(error);
            navigate(message.errorMessage , `/ramp/${id}/?locId=${locationId}`, message.statusCode);
          },
          refetchOnWindowFocus:false,
          select:(data:RampWorkingHoursResponse[])=>{
           if(data){
            const [ramp] = data;
            const {rampId , rampName, status , ...rest} = ramp;
            const keys = Object.keys(rest);
            const {mappedValues , result}= mapDecimalInStringValue(rest, keys);
            return {
                rampState:{
                    rampId,
                    rampName,
                    status,
                    ...result
                },
                mappedValues
            }
           };
         }
        }
    )


    const updateRampWorkingHours = async (ramp:RampWorkingHoursToDecimal):Promise<void>=>{
       setUpdateRampInProgress(true);
      try{
        await DekraLocationsService.updateRamp(ramp);
        await refetch();
      }catch(error){
        console.log(error)
      }finally{
        setUpdateRampInProgress(false);
      }
    }


    if((isLoading || isFetching) || updateRampInProgress){
      return <LinearProgress />
    }

    return (
        <RampUpdateForm
          {...rampState as RampWorkingHoursToString}
          schema={rampTimeCodeSchema as RampTimeCodeSchema}
          updateRampHandler={updateRampWorkingHours}
          locationId={locationId}
        />
    )
}

export default RampDetails;