import { useState, useEffect, FunctionComponent, ReactElement } from "react";
import { useSelector , useDispatch} from "react-redux";
import {Profile} from '../reducers/profile-reducer';
import { LocationsState } from "../reducers/locations-reducer";
import { RootState } from "../reducers";
import { Calendar, Task , TimeSlot} from "calendar/dist";
import * as signalR from '@microsoft/signalr';
import { startOfWeek , format, endOfWeek } from "date-fns";
import { de } from "date-fns/locale";
import CalendarService, { Location , DefaultLocationResponse , Services} from "../services/calendar-service";
import HubConnection from '../services/signalR-service';
import CalendarTopBar from "../components/calendar-top-bar";
import { head } from "lodash";
import { useQuery } from "react-query";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useErrorPage } from "../hooks";
import { timeStringToMilliseconds , dragAndDropUpdateTaskHandler, extractErrorMessage} from "../unit/utils";
import {BackBtn} from '../components/BackBTn';
import { setCalendarConfigData , setActiveLocation , setDefaultLocation , setWeekStartDate, setEvents} from "../actions/locations";
import {toast , ToastContainer } from 'react-toastify';
import store from '../store';

const useStyles = makeStyles({
  container: {
    minWidth: "600px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    flexGrow: 1,
  },
  back_btn:{
    alignSelf: 'flex-end',
    flexShrink: 0,
    margin: '10px'
  }
});

const DekraCalendar: FunctionComponent = (): ReactElement | null => {
  const classes = useStyles();
  const [HubConnectionState , setHubConnectionState] = useState<signalR.HubConnectionState | null>(HubConnection.isConnected ? HubConnection.isConnected : null);
  const {id} = useSelector<RootState, Profile>(state => state.profile);
  const {weekStartDate , activeLocation , defaultLocation , locations, events} =  useSelector<RootState, LocationsState>(state => state.calendar);
  const history = useHistory();
  const navigateToErrorPage = useErrorPage();
  const dispatch = useDispatch();

  const onWeekStartDate = (weekStartDate:Date)=>{
    dispatch(setWeekStartDate(weekStartDate));
  }

  const onConnectionFetchedError = (message:string):void=>{
    toast.error(`${message} ❌`);
  }

  const onConnectionFetchedSuccess = (state:signalR.HubConnectionState)=>{
    setHubConnectionState(state);
  }

  const { isLoading:locationsLoading } = useQuery(
    "locations",
    async ()=>{
      return Promise.all([
        CalendarService.getLocationsAsync(), 
        CalendarService.getDefaultUserLocation(id),
        CalendarService.getServices()
      ]);
    },
    {
      refetchOnWindowFocus: false,
      async onSuccess(data:[Location[] , DefaultLocationResponse | string, Services[]]) {
       if(data){
        const [locations , defLocation , services] = data;
        const defaultLocation = !!defLocation && locations.find( location => location.locationId === (defLocation as DefaultLocationResponse).locationId);
        if (!locations.length) {
          navigateToErrorPage("Calendar does not have locations!");
          return;
        }
        dispatch(setCalendarConfigData(
          locations,
          defaultLocation || head(locations) as Location,
          !!defLocation ? (defLocation as DefaultLocationResponse) : null,
          services
        ))
        await HubConnection.connect(onConnectionFetchedError, onConnectionFetchedSuccess);
       }
      },
      initialData: [[], '', []],
      enabled:!activeLocation,
      onError: navigateToErrorPage,
    }
  );

  
  const { refetch } = useQuery(
    ["tasks", activeLocation, weekStartDate],
    () =>
      CalendarService.getLocationEventsAsync(activeLocation!, weekStartDate),
    {
      enabled: !!activeLocation,
      refetchOnWindowFocus:false,
      onSuccess(events:Task[]){
        dispatch(setEvents(events));
      },
      onError: navigateToErrorPage,
    }
  );

  const updateDefaultLocation = (location:DefaultLocationResponse):void=>{
    dispatch(setDefaultLocation(location));
  }

  const activeLocationChangeHandler = (id: string): void => {
    const location = locations?.find((location) => location.locationId === id);
    if (location) {
      dispatch(setActiveLocation(location));
    }
  };

  const dragAndDropHandler = async (deletedEvent:Task, updatedEvent:Task):Promise<void>=>{
    const date = updatedEvent.date
    const dateFrom = new Date(date.getTime() + timeStringToMilliseconds(updatedEvent.from));
    const dateTo = new Date(date.getTime() + timeStringToMilliseconds(updatedEvent.to));
    const from = format(dateFrom, `yyyy-MM-dd'T'HH:mm:ss`);
    const to = format(dateTo, `yyyy-MM-dd'T'HH:mm:ss`);
    try{
      await CalendarService.createOrUpdateCalendarEvent(dragAndDropUpdateTaskHandler(updatedEvent, {from , to}), updatedEvent.id);
      await refetch();
    }catch(error){
     console.log(error);
     throw error;
    }
  }

  const deleteTaskHandler = (taskId:string):void=>{
    const events = store.getState().calendar.events;
    if(events.find( e => e.id === taskId)){
        refetch();
    }
  }

  const updateTaskEvent = (task:Task):void=>{
    const events = store.getState().calendar.events;
    const activeLocation = store.getState().calendar.activeLocation;
    const weekStartDate = store.getState().calendar.weekStartDate;
    
    const {id , locationId , from} = task;
    const TaskFromTime = new Date(from).getTime();
    if(activeLocation?.locationId === locationId){
      if(
        startOfWeek(weekStartDate , {locale:de , weekStartsOn:1}).getTime() >= TaskFromTime &&
        TaskFromTime <= endOfWeek(weekStartDate , {locale:de, weekStartsOn:1}).getTime()
        ){
         if(events.find( e => e.id === id)){
           refetch();
         }
      }
    }
  }

  const createTaskHandler = (task:Task):void=>{
    const activeLocation = store.getState().calendar.activeLocation;
    const weekStartDate = store.getState().calendar.weekStartDate;

    const { locationId , from} = task;
    const TaskFromTime = new Date(from).getTime();
    if(activeLocation?.locationId === locationId){
      if(
        startOfWeek(weekStartDate , {locale:de , weekStartsOn:1}).getTime() >= TaskFromTime &&
        TaskFromTime <= endOfWeek(weekStartDate , {locale:de, weekStartsOn:1}).getTime()
        ){
          refetch();
        }
    }
      
  }

  const closeHandler = async (error:Error | undefined):Promise<void>=>{
    setHubConnectionState(null);
    await HubConnection.connect(onConnectionFetchedError, onConnectionFetchedSuccess);
  }

  useEffect(()=>{
    if(HubConnectionState && HubConnectionState === 'Connected'){
      HubConnection.onTaskDeleteEvent(deleteTaskHandler);
      HubConnection.onTaskUpdateEvent(updateTaskEvent);
      HubConnection.onTaskCreateEvent(createTaskHandler);
      HubConnection.onclose(closeHandler);

      return ()=>{
        HubConnection.offTaskDeleteEvent();
        HubConnection.offTaskUpdateEvent();
        HubConnection.offTaskCreateEvent();
      }
    }
     // eslint-disable-next-line
  } , [HubConnectionState])

  const reconnectHandler = async ():Promise<void>=>{
    await HubConnection.connect(onConnectionFetchedError, onConnectionFetchedSuccess);
  }

  useEffect(()=>{
    if(HubConnectionState && HubConnectionState === 'Disconnected'){
      setHubConnectionState(null);
      reconnectHandler();
    }
    // eslint-disable-next-line
  }, [HubConnectionState])

  const onIntersectionHandler = ():void=>{
    toast.warn('Meetings can not intersect between each other ☝️');
  }

  const onSuccessHandler = ():void=>{
    toast.success('Meeting rescheduled successfully 👌');
  }

  const onNonWorkingDayHandler = ():void=>{
    toast.warn('Its a non-working day!☝️');
  }

  const onErrorHandler = (error:any):void=>{
    let {errorMessage} = extractErrorMessage(error);
    toast.error(`${errorMessage} ❌`);
  }

  const onTaskDropHandler = ():void=>{
    toast.error('You can not have several meetings in one time ❌');
  }

  const timeSlotClickHandler = (timeSlot:TimeSlot): void => {
    const {from , to , date, ramp } = timeSlot;
    history.push(`/kalendar/event?from=${from}&to=${to}&d=${date}&rampId=${ramp.rampId}&rFrom=${ramp.rampFrom}&rTo=${ramp.rampTo}&locationId=${activeLocation?.locationId ?? ''}`);
  };

  const eventClickHandler = (event: Task) => {
    history.push(`/kalendar/event?id=${event.id}&locationId=${activeLocation?.locationId ?? ''}`);
  };

  if (locationsLoading || !activeLocation) {
    return <LinearProgress />;
  }

  return (
  <>
    <div className={classes.container}>
      <div className={classes.back_btn}>
         <BackBtn />
      </div>
      <CalendarTopBar
        locale={de}
        activeLocation={activeLocation}
        defaultLocation={defaultLocation}
        weekStartDate={weekStartDate}
        locations={locations!}
        onWeekChange={onWeekStartDate}
        onLocationChange={activeLocationChangeHandler}
        onDefaultLocationUpdate={updateDefaultLocation}
      />
      <Calendar
        events={events}
        weekStartDate={weekStartDate}
        weekDuration={7}
        onTimeSlotClick={timeSlotClickHandler}
        onEventClick={eventClickHandler}
        week={activeLocation!.weekDay}
        onDragAndDropHandler={dragAndDropHandler}
        onDragAndDropCallbacks={{
          onIntersection:onIntersectionHandler,
          onSuccess:onSuccessHandler,
          onNonWorkingDay:onNonWorkingDayHandler,
          onError:onErrorHandler,
          onTaskDrop:onTaskDropHandler
        }}
      />
    </div>
    <ToastContainer
      position='top-center'
      autoClose={7000}
      hideProgressBar={false}
      pauseOnHover={true}
      draggable={true}
      draggableDirection={'x'}
      theme='colored'
    />
  </>
  );
};

export default DekraCalendar;
