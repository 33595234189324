export const imageExtensions = [
  "gif",
  "ico",
  "jpeg",
  "jpg",
  "tga",
  "tiff",
  "tif",
  "svg",
  "png",
  "webp",
];

export enum RepairType {
  SmartRepair = 528970001,
  Lackierung = 528970002,
  Werkstatt = 528970003,
  Aufbereitung = 528970004,
  Twinner = 528970005,
}

export enum Statuses{
  PlanningIsPending = 'Planung erwartet',
  IsPlanned = 'Geplant',
  InProcess = 'In Arbeit',
  Done = 'Abgeschlossen',
}


export enum RepairOrderStatus {
  Neu = 528970000,
  New = 528970000,
  Geplant = 528970001,
  Scheduled = 528970001,
  InArbeit = 528970002,
  InProgress = 528970002,
  PlanungErwartet = 528970004,
  Abgeschlossen = 528970003,
  Closed = 528970003,
  AuftragPlanungErwartet = 528970009
}
