import { ChangeEvent, FunctionComponent, ReactElement, useState } from "react";
import {
  makeStyles,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  List,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useErrorPage } from "../../hooks";
import { getErrorMessage, isImageExtension } from "../../unit/utils";
import damageService from "../../services/damage-service";
import * as Labels from "../../unit/labels";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ImageIcon from "@material-ui/icons/Image";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";
import { head } from "lodash";
import { useRef } from "react";

interface FormState {
  vehicleId: string;
  repairType: string;
  component: string;
  damage: string;
  description: string;
  calculationCost: string;
  imageId: string;
}

interface DamageFormProps {
  vehicleId: string;
  folderName: string;
  onCancel(): void;
}

const useStyles = makeStyles({
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: "20px",
    "& > button": {
      marginLeft: "20px",
    },
  },
});

const onlyImageMessage = "Nur Bild erlaubt!";

const DamageForm: FunctionComponent<DamageFormProps> = (
  props
): ReactElement => {
  const classes = useStyles();
  const { vehicleId, onCancel, folderName } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const navigateToErrorPage = useErrorPage();
  const [form, setForm] = useState<FormState>({
    vehicleId,
    repairType: "528970001",
    component: "",
    damage: "",
    description: "",
    calculationCost: "0",
    imageId: "",
  });

  // file state
  const [fileError, setFileError] = useState<string>("");
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement>(null);

  const changeHandler = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    const name = event.target.name;
    if (!name) {
      return;
    }
    const value = event.target.value;
    if(name === "calculationCost" &&value && !/^\d+\.?\d{0,2}$/g.test(value as string)){
      return;
    }

    setForm((form) => ({ ...form, [name]: value }));
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = head(event.target.files);
    console.log(file)
    if (!file || !isImageExtension(file.name)) {
      setFileError(onlyImageMessage);
      return;
    }
    setFile(file);
    inputRef.current!.value = "";
  };

  const submitHandler = async (): Promise<void> => {
    try {
      setLoading(true);
      let imageId = "";
      if (file) {
        imageId = await damageService.uploadImage(file, folderName);
      }
      await damageService.create({ ...form, imageId });
      window.dispatchEvent(new Event("refresh-vehicle-table"));
      onCancel();
      setLoading(false);
    } catch (err) {
      navigateToErrorPage(getErrorMessage(err));
    }
  };

  const removeFile = (): void => {
    setFile(undefined);
    inputRef.current!.value = "";
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="component"
            label="Bauteil"
            fullWidth
            value={form.component}
            onChange={changeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="damage"
            label="Schaden"
            fullWidth
            value={form.damage}
            onChange={changeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Beschreibung"
            fullWidth
            value={form.description}
            onChange={changeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="calculationCost"
            label="Kosten (Berechnung)"
            fullWidth
            value={form.calculationCost}
            onChange={changeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="repair-type">Reparaturweg</InputLabel>
            <Select
              labelId="repair-type"
              id="repair-type"
              name="repairType"
              onChange={changeHandler}
              value={form.repairType}
              fullWidth
            >
              <MenuItem value="528970001">Smart Repair</MenuItem>
              <MenuItem value="528970002">Lackierung</MenuItem>
              <MenuItem value="528970003">Werkstatt</MenuItem>
              <MenuItem value="528970004">Aufbereitung</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {file && (
            <List dense>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={removeFile}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          )}
          {fileError && <Alert severity="error">{fileError}</Alert>}
          <Button component="label" startIcon={<AttachFileIcon />}>
            Datei hochladen
            <input
              ref={inputRef}
              type="file"
              hidden
              accept="image/*"
              onChange={inputChangeHandler}
            />
          </Button>
        </Grid>
      </Grid>
      <div className={classes.btnContainer}>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={submitHandler}
          startIcon={loading ? <CircularProgress size={19} /> : null}
        >
          {Labels.Save}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onCancel}
          disabled={loading}
        >
          Abbrechen
        </Button>
      </div>
    </>
  );
};

export default DamageForm;
