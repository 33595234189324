import {useState, useMemo, FunctionComponent, ReactElement , ChangeEvent, Fragment} from "react";
import { TextField, Grid , Button, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {mapToObjectFormat, transform, convertToDecimalFormat} from '../../unit/utils';
import TimePickerStyled from "./TimePicker";
import DekraLocationsService from "../../services/dekra-locations-service";
import clsx from 'clsx';
import * as Labels from '../../unit/labels';

const useStyles = makeStyles({
    status:{
        display:'flex',
        padding:'20px 0px 10px 0px',
        alignItems:'center'
    },
    status_title:{
        marginRight:10
    },
    container:{
        margin:'10px 10px 10px 10px'
    },
    container_underline:{
      borderBottom: '1px solid #D9D9D9',
      paddingBottom: 15,
    },
    day_title:{
        alignSelf:'center',
        color: '#706D6D',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0.08px',
    },
    time:{
      color: '#102F3F',
      fontFamily: 'Open Sans',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    all_days_active:{
      color: '#D1050C',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      textDecorationLine: 'underline',
      textTransform: 'uppercase',
    },
    all_days_inactive:{
      color: '#706D6D!important',
    },
    cancel_days:{
      display: 'flex', 
      justifyContent:'flex-end', 
      flexGrow:1,
    },
    active_link:{
      cursor:'pointer'
    },
    inactive_link:{
      cursor:'auto'
    },
    btnContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingTop: "20px",
        "& > button": {
          marginLeft: "20px",
        },
      },
});

interface NewDefaultRamp{
    rampName: string,
    mondayFrom: string,
    mondayTo: string,
    tuesdayFrom: string,
    tuesdayTo: string,
    wednesdayFrom: string,
    wednesdayTo: string,
    thursdayFrom: string,
    thursdayTo: string,
    fridayFrom: string,
    fridayTo: string,
    saturdayFrom: string,
    saturdayTo: string,
    sundayFrom: string,
    sundayTo: string,
}

type ApplyForAllDays = Omit<NewDefaultRamp, 'rampName' | 'mondayFrom' | 'mondayTo' | 'sundayFrom' | 'sundayTo'>;
type CanceledForAllDays = ApplyForAllDays;

interface RampCreationFormProps{
    onSoftRefetch():Promise<void>;
    onCloseHandler():void;
    LocationId:string;
}

const getDefaultState =():NewDefaultRamp=>({
    rampName: '',
    mondayFrom: '---',
    mondayTo: '---',
    tuesdayFrom: '---',
    tuesdayTo: '---',
    wednesdayFrom: '---',
    wednesdayTo: '---',
    thursdayFrom: '---',
    thursdayTo: '---',
    fridayFrom: '---',
    fridayTo: '---',
    saturdayFrom: '---',
    saturdayTo: '---',
    sundayFrom: '---',
    sundayTo: '---',
})

interface TimePickersState{
  [key:string]:boolean
}

const getDefaultTimePickersState = ():TimePickersState=>({
   'monday':false,
   'tuesday': false,
   'wednesday': false,
   'thursday':false,
   'friday':false,
   'saturday':false,
   'sunday':false
})

export const RampCreationForm:FunctionComponent<RampCreationFormProps> = ({LocationId , onSoftRefetch, onCloseHandler}):ReactElement=>{
   const [rampState , setRampState] = useState<NewDefaultRamp>(getDefaultState());
   const [timePickersState , setTimePickersState] = useState<TimePickersState>(getDefaultTimePickersState());
   const [loading , setLoading] = useState<boolean>(false);
   const { rampName } = rampState;
   const classes = useStyles();

   const schema = transform(mapToObjectFormat());

   const optionSetValues = useMemo(()=>{
     return [...Object.keys(schema)];
   }, []);


   const durationPoints:string[] = useMemo(()=>{
    const keys = Object.keys(rampState);
    return keys.slice(1, keys.length);
   }, []);

   const onRampNameChangeHandler = (e:ChangeEvent<HTMLInputElement>)=>{
      const name = e.target.name;
      const value = e.target.value;
      setRampState( prev => ({...prev , [name]:value}));
   }

  const onTimeChangeHandler =(name:string , value:string):void=>{
    setRampState( prev => ({...prev , [name]:value}));
  }

  const submitHandler = async ()=>{
     const toDecimalFormat = convertToDecimalFormat({...rampState, locationId:LocationId} , schema);
     setLoading(true);
     try{
      const {Id , ...rest} = toDecimalFormat;
      await DekraLocationsService.createRamp({...rest, LocationId});
      await onSoftRefetch();
      setLoading(false);
      onCancel();
     }catch(error){
        console.log(error);
        setLoading(false);
     }
   }

  const applyToAllDaysHandler = ()=>{
    if(!isMondaySetup()){
       return;
    }

    setRampState( prev => {
      const { rampName, mondayFrom, mondayTo, sundayFrom, sundayTo, ...rest} = prev;

      const appliedToAllDays = Object.keys(rest).reduce((prevState:ApplyForAllDays , day:string):ApplyForAllDays=>{
        const currentDay = day as keyof ApplyForAllDays;

        if(day.indexOf('From') > -1){
          prevState[currentDay] = mondayFrom
        }else{
          prevState[currentDay] = mondayTo
        }
        return prevState;
      }, { ...rest } as ApplyForAllDays);

      return {
        rampName,
        mondayFrom,
        mondayTo,
        ...appliedToAllDays,
        sundayFrom,
        sundayTo,
      }
      })
  }

  const cancelToAllDaysHandler = ()=>{
    if(!isMondaySetup()){
      return;
    }

    setRampState( prev => {
      const { rampName, mondayFrom, mondayTo, sundayFrom, sundayTo, ...rest} = prev;

      const canceledToAllDays = Object.keys(rest).reduce((prevState:CanceledForAllDays , day:string):CanceledForAllDays=>{
        const currentDay = day as keyof CanceledForAllDays;
        prevState[currentDay] = notAvailable;
        return prevState;
       }, { ...rest } as CanceledForAllDays);

       return {
        rampName,
        mondayFrom,
        mondayTo,
        ...canceledToAllDays,
        sundayFrom,
        sundayTo,
       }
      })
  }

  const timePickersStateHandler = (day:string, isOpen:boolean):void=>{
    setTimePickersState( (prev:TimePickersState) => (
      Object.keys(prev).reduce((initialState , currentDay:string):TimePickersState=>{
          if(currentDay !== day && initialState[currentDay] && isOpen){
            initialState[currentDay] = false
          }
          if(currentDay === day && isOpen){
            initialState[currentDay] = true
          }
          if(currentDay === day && !isOpen){
            initialState[currentDay] = false
          }
          return initialState;
      },{...prev}) 
     )
    )
  }

  const onCancel = ()=>{
     onCloseHandler();
  }

  const groupByDay = (items:string[])=>{
     let result:string[][]= [];
     let numberOfPairs = items.length / 2;
     let pairNumber = 1;
     let startPosition = 0;
     while(pairNumber <= numberOfPairs){
       result.push([...items.slice(startPosition , startPosition + 2)]);
       pairNumber+=1;
       startPosition+=2;
     }
     return result;
  }

  const isMondaySetup = ():boolean=>{
     return rampState.mondayFrom !== notAvailable && rampState.mondayTo !== notAvailable;
  }

   const renderRampSchedule = ():JSX.Element[]=>{
     return groupByDay(durationPoints)
           .map((points:string[])=>{
            const [from , to] = points;
            const endOffDayFromIndex = from.indexOf('From');
            const endOffDayToIndex = to.indexOf('To');
            let dayFrom = from.slice(0 , endOffDayFromIndex);
            let dayTo = to.slice(0 , endOffDayToIndex);
            let firstUpperCaseLetter = dayFrom[0].toUpperCase();

            const setuped = isMondaySetup();

        return(
          <Fragment key={from + to}>
             <Grid container xs={12} direction="row" className={clsx(classes.container, !dayTo.includes('monday') ? classes.container_underline : {} )}>
                <Grid item xs={3} className={classes.day_title}>
                  {`${firstUpperCaseLetter + dayFrom.slice(1)}`}
                </Grid>
                <Grid item xs={3}>
                  <TimePickerStyled
                    onTimeSelectHandler={onTimeChangeHandler}
                    fromValue={schema[rampState[from as keyof Omit<NewDefaultRamp, 'rampName'|'status'>]]?.stringValue ?? notAvailable}
                    toValue={schema[rampState[to as keyof Omit<NewDefaultRamp, 'rampName'|'status'>]]?.stringValue ?? notAvailable} 
                    timeList={optionSetValues}
                    fromDay={from}
                    toDay={to}
                    isOpen={timePickersState[dayFrom]}
                    timePickersStateHandler={timePickersStateHandler}
                  />
                </Grid>
              </Grid>
              {dayTo.includes('monday') && (
                <Grid container xs={12} direction="row" className={clsx(classes.container , classes.container_underline)}>
                  <Grid item xs={5} className={clsx(classes.all_days_active, {[classes.all_days_inactive]:!setuped})}>
                    <div 
                      className={clsx({} , {[classes.active_link]:setuped, [classes.inactive_link]:!setuped})}
                      onClick={applyToAllDaysHandler}
                    >
                        Apply to all days
                    </div>
                  </Grid>
                  <Grid item xs={5} className={clsx(classes.all_days_active, {[classes.all_days_inactive]: !setuped})}>
                    <div
                      className={clsx(classes.cancel_days , {[classes.active_link]:setuped, [classes.inactive_link]:!setuped})}
                      onClick={cancelToAllDaysHandler}
                    >
                        Cancel to all days
                    </div>
                  </Grid>
                </Grid>
               )
              }
          </Fragment>
        )
     })
   }

   const notAvailable = optionSetValues[optionSetValues.length - 1];

   return (
     <Grid container spacing={3}>
        <Grid item xs={12}>
            <TextField
              name='rampName'
              label="Name"
              placeholder='---'
              fullWidth
              value={rampName}
              onChange={onRampNameChangeHandler}
            />
        </Grid>
        <Grid container xs={12} direction="row" className={classes.container}>
            <Grid item xs={1} className={classes.time}>
              Time
            </Grid>
        </Grid>
        {renderRampSchedule()}
        <Grid item xs={12}>
            <div className={classes.btnContainer}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={submitHandler}
                startIcon={loading ? <CircularProgress size={19} /> : null}
               >
                {Labels.Save}
               </Button>
               <Button
                variant="contained"
                color="secondary"
                onClick={onCancel}
                disabled={loading}
               >
                Abbrechen
               </Button>
           </div>
        </Grid>
    </Grid>
   )
}

