import { ChangeEvent, FunctionComponent, ReactElement, useState } from "react";
import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { head } from "lodash";
import { isImageExtension } from "../../../unit/utils";
import damageService from "../../../services/damage-service";
import { useErrorPage } from '../../../hooks';

const useStyles = makeStyles({
  input: {
    display: "none",
  },
});

interface IconButtonForUploadProps {
  folderName: string;
  entityName: string;
  damageId: string;
  onImageIdChange(id: string): void;
}

const IconButtonForUpload: FunctionComponent<IconButtonForUploadProps> = (
  props
): ReactElement => {
  const { damageId, onImageIdChange, folderName, entityName } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const navigateToErrorPage = useErrorPage();

  const changeHandler = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (loading) {
      return;
    }
    const files = event.target.files;
    const file = head(files);
    if (!file || !isImageExtension(file.name)) {
      return;
    }

    try {
      setLoading(true);
      const imageId = await damageService.uploadImage(file, folderName, entityName);
      await damageService.setImageId(damageId, imageId);
      onImageIdChange(imageId);
      setLoading(false);
    } catch (err:any) {
      // TODO: Snackbar with errror
      navigateToErrorPage(err.message);
    }
  };


  const inputId = `icon-button-file_${damageId}`;
  return (
    <>
      <input
        accept="image/*"
        id={inputId}
        type="file"
        multiple={false}
        className={classes.input}
        onChange={changeHandler}
      />
      <label htmlFor={inputId}>
        <IconButton component="span">
          {loading && <CircularProgress size={20} />}
          {!loading && <CloudUploadIcon />}
        </IconButton>
      </label>
    </>
  );
};

export default IconButtonForUpload;
