import {useState, useMemo, useEffect, FunctionComponent, ReactElement , ChangeEvent} from "react";
import { useLocation } from 'react-router-dom';
import { useSelector , useDispatch} from "react-redux";
import { setActiveTab , setRampChanges} from "../../actions/edit";
import { RootState } from "../../reducers";
import { InitialState } from "../../reducers/edit-reducer";
import FormContainer from "../../components/FormContainer";
import {ApproveDialog} from '../../components/Dialog';
import TimePickerStyled from "./TimePicker";
import AlertStyled from "./AlertStyled";
import { TextField, Grid , Switch, FormControlLabel , Typography, Button, CircularProgress } from "@material-ui/core";
import {
  Fab,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {ArrowBack } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import DekraLocationsService , {RampWorkingHoursToDecimal, RampWorkingHoursToString, RampStatus} from '../../services/dekra-locations-service';
import {RampTimeCodeSchema , convertToDecimalFormat, transform, extractErrorMessage} from '../../unit/utils';
import {LinearProgress} from "@material-ui/core";
import { useErrorPage } from "../../hooks";
import {navigateToDekraLocation} from '../navigate';
import {makeStyles} from "@material-ui/core";
import {isEqual, isEmpty} from "lodash";
import clsx from 'clsx';
import * as Labels from '../../unit/labels';


const useStyles = makeStyles({
    status:{
        display:'flex',
        padding:'20px 0px 10px 0px',
        alignItems:'center',
        height:60
    },
    status_title:{
        marginRight:10
    },
    container:{
        margin:'10px 10px 10px 10px',
        maxWidth: 420,
    },
    container_underline:{
      borderBottom: '1px solid #D9D9D9',
      paddingBottom: 15,
    },
    day_title:{
        alignSelf:'center',
        color: '#706D6D',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0.08px',
    },
    time:{
      color: '#102F3F',
      fontFamily: 'Open Sans',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    all_days_active:{
      color: '#D1050C',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      textDecorationLine: 'underline',
      textTransform: 'uppercase',
    },
    all_days_inactive:{
      color: '#706D6D!important',
    },
    cancel_days:{
      display: 'flex', 
      justifyContent:'flex-end', 
      flexGrow:1,
    },
    active_link:{
      cursor:'pointer'
    },
    inactive_link:{
      cursor:'auto'
    },
    btns:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        gap:15
    },
    btn:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding: '10px 10px 10px 10px',
    },
  });

interface TimePickersState{
  [key:string]:boolean
}

type ApplyToAllDays = Omit<RampWorkingHoursToString, 'locationId' | 'rampId' | 'rampName' | 'mondayFrom' | 'mondayTo' | 'status' | 'sundayFrom' | 'sundayTo'>;
type CanceledToAllDays = Omit<RampWorkingHoursToString, 'locationId' | 'rampId' | 'rampName' | 'status' | 'sundayFrom' | 'sundayTo'>;

interface LocationState{
  changedValue:any;
  rampId:string;
  redirectTo: string;
}

interface RampFormProps extends RampWorkingHoursToString{
  schema:RampTimeCodeSchema;
  updateRampHandler(ramp:RampWorkingHoursToDecimal):Promise<void>;
}

type RampFormState = Omit<RampWorkingHoursToString, 'status'>;

const getDefaultTimePickersState = ():TimePickersState=>({
  'monday':false,
  'tuesday': false,
  'wednesday': false,
  'thursday':false,
  'friday':false,
  'saturday':false,
  'sunday':false
})



export const RampUpdateForm:FunctionComponent<RampFormProps>= (props):ReactElement=>{
    const { schema, status, updateRampHandler, ...formState} = props;
    const [rampFormState , setRampFormState] = useState<RampFormState>({...formState});
    const [statusState , setStatusState] = useState<{inProgress:boolean , status:RampStatus, error:string}>({inProgress:false , status, error:''});
    const [agreeFrameVisible, setAgreeFrameVisible] = useState<boolean>(false);
    const [loading , setLoading] = useState<boolean>(false);
    const [timePickersState , setTimePickersState] = useState<TimePickersState>(getDefaultTimePickersState());
    const {activeLocationTab} = useSelector<RootState , InitialState>( state => state.edit);
    const navigateToErrorPage = useErrorPage();
    const {rampName , rampId } = rampFormState;
    const {status:isActive , inProgress} = statusState;
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    const fabSize =  smUp ? 'medium': 'small';
    const dispatch = useDispatch();
    const {state}:{state:LocationState} = useLocation();
    const classes = useStyles();

    const newSchema = transform(schema);

    const optionSetValues:string[] = useMemo(()=>{
      return [...Object.keys(newSchema)];
    }, []);

    useEffect(()=>{
      if(!activeLocationTab){
        dispatch(setActiveTab(1));
      }
    } , []);

    useEffect(()=>{
      if(state?.changedValue && !isEmpty(state.changedValue)){
        const comparable = isEqual(rampFormState, formState);
        if(state.rampId === rampId){
          if(comparable){
            clearHandler();
          }else{
            setAgreeFrameVisible(true);
          }
        }
      }
   }, [state?.changedValue])

    const switchChangeHandler = async (event: ChangeEvent<HTMLInputElement>, checked: boolean)=>{
      const name = event.target.name;
      setStatusState(prev => ({...prev , inProgress:true}));
      try{
        if(checked){
         await DekraLocationsService.updateRampStatus(checked , rampId!);
        }else{
         await DekraLocationsService.updateRampStatus(checked , rampId!);
        }
        setStatusState( prev =>({...prev , inProgress:false, [name]:checked ? 'Aktiv' : 'Inaktive'}));
      }catch(error:any){
        console.log(error);
        const message = extractErrorMessage(error);
        setStatusState(prev => ({...prev , inProgress:false, error:message.errorMessage}));
      }

    }

    const onRampNameChangeHandler = (e:ChangeEvent<HTMLInputElement>)=>{
     const name = e.target.name;
     const value = e.target.value;
     setRampFormState( state =>({...state , [name]:value}));
    }

    const onUpdateHandler = ()=>{
     clearHandler();
     const result = convertToDecimalFormat(rampFormState, newSchema);
     updateRampHandler(result);
    }

    const onCloseHandler = ()=>{
      setStatusState(prev => ({...prev , error:'' }));
    }

    const onTimeChangeHandler =(name:string , value:string):void=>{
      setRampFormState( state =>({...state , [name]:value === timeNotAvailable ? null : value}));
    }

    const cancelToAllDaysHandler = ()=>{

      setRampFormState( prev => {
        const {locationId , rampId, rampName, sundayFrom, sundayTo, ...rest} = prev;

        const canceledToAllDays = Object.keys(rest).reduce((prevState:CanceledToAllDays , day:string):CanceledToAllDays=>{
          const currentDay = day as keyof CanceledToAllDays;
          prevState[currentDay] = formState[currentDay];
          return prevState;
        }, { ...rest } as CanceledToAllDays);

        return {
          locationId ,
          rampId,
          rampName,
          sundayFrom,
          sundayTo,
          ...canceledToAllDays,
        }
      })
    }

    const applyToAllDaysHandler = ()=>{

     setRampFormState( prev => {
      const {locationId , rampId, rampName, mondayFrom, mondayTo, sundayFrom, sundayTo, ...rest} = prev;

      const appliedToAllDays = Object.keys(rest).reduce((prevState:ApplyToAllDays , day:string):ApplyToAllDays=>{
        const currentDay = day as keyof ApplyToAllDays;

        if(day.indexOf('From') > -1){
          prevState[currentDay] = mondayFrom
        }else{
          prevState[currentDay] = mondayTo
        }
        return prevState;
      }, { ...rest } as ApplyToAllDays);

      return {
        locationId ,
        rampId,
        rampName,
        mondayFrom,
        mondayTo,
        sundayFrom,
        sundayTo,
        ...appliedToAllDays,
      }
     })
    }

    const timePickersStateHandler = (day:string, isOpen:boolean):void=>{
      setTimePickersState( (prev:TimePickersState) => (
        Object.keys(prev).reduce((initialState , currentDay:string):TimePickersState=>{
            if(currentDay !== day && initialState[currentDay] && isOpen){
              initialState[currentDay] = false
            }
            if(currentDay === day && isOpen){
              initialState[currentDay] = true
            }
            if(currentDay === day && !isOpen){
              initialState[currentDay] = false
            }
            return initialState;
        },{...prev})
       )
      )
    }

    const clearHandler = ()=>{
      dispatch(setRampChanges( {rampId: '' , changedValue: {} }));
    }

    const disAgreeHandler = ()=>{
      clearHandler();
      setAgreeFrameVisible(false);
    }

    const agreeHandler = async ()=>{
      setAgreeFrameVisible(false);
      setLoading(true);
      try{
        await confirmUpdateRampHandler(state.changedValue, newSchema);
        clearHandler();
        setLoading(false);
      }catch(error:any){
        console.log(error);
        const {errorMessage, statusCode} = extractErrorMessage(error);
        navigateToErrorPage(errorMessage, `/ramp/${rampId}`, statusCode);
      }
    }

    const confirmUpdateRampHandler = async (updatedRampState:RampFormState, schema:RampTimeCodeSchema):Promise<void>=>{
      const result = convertToDecimalFormat(updatedRampState, schema);
      try{
        await DekraLocationsService.updateRamp(result);
      }catch(error:any){
        throw error;
      }
    }


    const disabled:boolean = useMemo(()=>{
      return  isEqual(rampFormState , formState) ? true : false;
    }, [rampFormState , formState])


    useEffect(()=>{
      if(!disabled){
        dispatch(
          setRampChanges({
            rampId:rampId!,
            changedValue:rampFormState
          })
        )
      }
    },[rampFormState, disabled])

    const goBackHandler = async ()=>{
      if(disabled) return navigateToDekraLocation(rampFormState.locationId);

      setLoading(true);
      try{
          await confirmUpdateRampHandler(rampFormState, newSchema);
          clearHandler();
          navigateToDekraLocation(rampFormState.locationId);
      }catch(error:any){
          console.log(error);
          const {errorMessage, statusCode} = extractErrorMessage(error);
          navigateToErrorPage(errorMessage, `/ramp/${rampId}/?locId=${rampFormState.locationId}`, statusCode);
      }
    }

    const Status = isActive === 'Aktiv' ? 'Active' : 'Inactive';
    const timeNotAvailable = optionSetValues[optionSetValues.length - 1];


  if(loading){
     return <LinearProgress />
  }

  return (
     <FormContainer
       title={rampName}
       rightElement={
       <div className={classes.btns}>
          <Button
           variant="contained"
           color='primary'
           onClick={onUpdateHandler}
           disabled={disabled}
           className={classes.btn}
          >
            <SaveIcon fontSize="small"/>
            <Typography style={{fontSize:15}}>
              {Labels.Save}
            </Typography>
          </Button>
          <Fab
            variant="extended"
            size={fabSize}
            aria-label="back"
            onClick={goBackHandler}
          >
            <ArrowBack />
            {Labels.Back}
          </Fab>
        </div>
       }
     >
        <AlertStyled visible={!!statusState.error} closeHandler={onCloseHandler}>
           {statusState.error}
        </AlertStyled>
        <Grid container spacing={3} direction="column">
            <Grid item xs={12}>
             <TextField
                name='rampName'
                label="Name"
                fullWidth
                value={rampName}
                onChange={onRampNameChangeHandler}
             />
             <div className={classes.status}>
                <Typography className={classes.status_title}>Status</Typography>
                {inProgress ? 
                  (<CircularProgress size={25}/>)
                  :
                 (<FormControlLabel
                  control={<Switch checked={isActive === 'Aktiv'} onChange={switchChangeHandler} name='status' inputProps={{ 'aria-label': `${Status}` }} />}
                  label={Status}
                />)}
             </div>
            </Grid>
            <Grid container xs={12} direction="row" className={classes.container}>
              <Grid item xs={1} className={classes.time}>
                Time
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={classes.container}>
              <Grid item xs={4} className={classes.day_title}>
                Monday
              </Grid>
              <Grid item xs={4}>
                <TimePickerStyled 
                  onTimeSelectHandler={onTimeChangeHandler} 
                  fromValue={rampFormState.mondayFrom ? newSchema[rampFormState.mondayFrom].stringValue : timeNotAvailable}
                  toValue={rampFormState.mondayTo ? newSchema[rampFormState.mondayTo].stringValue : timeNotAvailable} 
                  timeList={optionSetValues}
                  fromDay='mondayFrom'
                  toDay='mondayTo'
                  isOpen={timePickersState['monday']}
                  timePickersStateHandler={timePickersStateHandler}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={clsx(classes.container, classes.container_underline)}>
              <Grid item xs={6} className={clsx(classes.all_days_active)}>
                <div
                  className={clsx(classes.active_link)}
                  onClick={applyToAllDaysHandler}
                >
                    Apply to all days
                </div>
              </Grid>
              <Grid item xs={6} className={clsx(classes.all_days_active)}>
                <div
                  className={clsx(classes.cancel_days , classes.active_link)}
                  onClick={cancelToAllDaysHandler}
                >
                    Cancel to all days
                </div>
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={clsx(classes.container, classes.container_underline)}>
              <Grid item xs={4} className={classes.day_title}>
                Tuesday
              </Grid>
              <Grid item xs={4}>
                <TimePickerStyled 
                  onTimeSelectHandler={onTimeChangeHandler} 
                  fromValue={rampFormState.tuesdayFrom ? newSchema[rampFormState.tuesdayFrom].stringValue : timeNotAvailable}
                  toValue={rampFormState.tuesdayTo ? newSchema[rampFormState.tuesdayTo].stringValue : timeNotAvailable} 
                  timeList={optionSetValues}
                  fromDay='tuesdayFrom'
                  toDay='tuesdayTo'
                  isOpen={timePickersState['tuesday']}
                  timePickersStateHandler={timePickersStateHandler}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={clsx(classes.container, classes.container_underline)}>
              <Grid item xs={4} className={classes.day_title}>
                Wednesday
              </Grid>
              <Grid item xs={4}>
                <TimePickerStyled 
                  onTimeSelectHandler={onTimeChangeHandler} 
                  fromValue={rampFormState.wednesdayFrom ? newSchema[rampFormState.wednesdayFrom].stringValue : timeNotAvailable}
                  toValue={rampFormState.wednesdayTo ? newSchema[rampFormState.wednesdayTo].stringValue : timeNotAvailable} 
                  timeList={optionSetValues}
                  fromDay='wednesdayFrom'
                  toDay='wednesdayTo'
                  isOpen={timePickersState['wednesday']}
                  timePickersStateHandler={timePickersStateHandler}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={clsx(classes.container, classes.container_underline)}>
              <Grid item xs={4} className={classes.day_title}>
                Thursday
              </Grid>
              <Grid item xs={4}>
                <TimePickerStyled 
                  onTimeSelectHandler={onTimeChangeHandler} 
                  fromValue={rampFormState.thursdayFrom ? newSchema[rampFormState.thursdayFrom].stringValue : timeNotAvailable}
                  toValue={rampFormState.thursdayTo ? newSchema[rampFormState.thursdayTo].stringValue : timeNotAvailable} 
                  timeList={optionSetValues}
                  fromDay='thursdayFrom'
                  toDay='thursdayTo'
                  isOpen={timePickersState['thursday']}
                  timePickersStateHandler={timePickersStateHandler}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={clsx(classes.container, classes.container_underline)}>
              <Grid item xs={4} className={classes.day_title}>
                Friday
              </Grid>
              <Grid item xs={4}>
                <TimePickerStyled 
                  onTimeSelectHandler={onTimeChangeHandler} 
                  fromValue={ rampFormState.fridayFrom ? newSchema[rampFormState.fridayFrom].stringValue : timeNotAvailable}
                  toValue={rampFormState.fridayTo ? newSchema[rampFormState.fridayTo].stringValue : timeNotAvailable} 
                  timeList={optionSetValues}
                  fromDay='fridayFrom'
                  toDay='fridayTo'
                  isOpen={timePickersState['friday']}
                  timePickersStateHandler={timePickersStateHandler}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={clsx(classes.container, classes.container_underline)}>
              <Grid item xs={4} className={classes.day_title}>
                Saturday
              </Grid>
              <Grid item xs={4}>
                <TimePickerStyled 
                  onTimeSelectHandler={onTimeChangeHandler} 
                  fromValue={rampFormState.saturdayFrom ? newSchema[rampFormState.saturdayFrom].stringValue : timeNotAvailable}
                  toValue={rampFormState.saturdayTo ? newSchema[rampFormState.saturdayTo].stringValue : timeNotAvailable} 
                  timeList={optionSetValues}
                  fromDay='saturdayFrom'
                  toDay='saturdayTo'
                  isOpen={timePickersState['saturday']}
                  timePickersStateHandler={timePickersStateHandler}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className={clsx(classes.container, classes.container_underline)}>
              <Grid item xs={4} className={classes.day_title}>
                Sunday
              </Grid>
              <Grid item xs={4}>
                <TimePickerStyled 
                  onTimeSelectHandler={onTimeChangeHandler} 
                  fromValue={rampFormState.sundayFrom ? newSchema[rampFormState.sundayFrom].stringValue : timeNotAvailable}
                  toValue={rampFormState.sundayTo ? newSchema[rampFormState.sundayTo].stringValue : timeNotAvailable} 
                  timeList={optionSetValues}
                  fromDay='sundayFrom'
                  toDay='sundayTo'
                  isOpen={timePickersState['sunday']}
                  timePickersStateHandler={timePickersStateHandler}
                />
              </Grid>
            </Grid>
       </Grid>
       {agreeFrameVisible && <ApproveDialog agreeHandler={agreeHandler} disAgreeHandler={disAgreeHandler} isOpen={agreeFrameVisible}/>}
    </FormContainer>
  )
}