import axios, { AxiosResponse } from 'axios';
import { CALENDAR_API_URL } from '../urls';
import AuthService from './auth-service';
import { Task, Day } from 'calendar/dist';
import {expandWithFields , DragAndDropUpdateCommand} from '../unit/utils';

type DayStringValue = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday"

export interface DaySchedule{
  day:DayStringValue;
  from:string;
  to:string;
}
interface RampsWorkinDayAndHours{
  id:string;
  name:string;
  schedule:DaySchedule[]
}

export type RapmsSchedule = RampsWorkinDayAndHours[];

export interface Location {
  locationId:string;
  locationName:string;
  weekDay: Week;
}

export type Week = Day[];

export interface CreateTaskCommand {
  title: string;
  locationId: string | null;
  serviceId?:string | null;
  from: string;
  to: string;
  note?: string | null;
  isWholeDay: boolean;
  rampId:string | null;
  fahrzeugId?:string;
  kennzeichen:string | null;
  customerOnSite:boolean;
}

interface TaskResponse{
  id: string;
  title: string;
  from: string;
  to: string;
  isWholeDay: boolean;
  note?: string | null;
  rampId:string;
  locationId:string | null;
  serviceId?:string |null;
  colorCode: string | null;
  serviceName: string | null;
  kennzeichen: string | null;
  carOnSite: boolean;
  customerOnSite:boolean;
}

export interface Services{
  id:string;
  name:string;
  duration:number
}

export interface DefaultLocationResponse{
  defaultLocationRecordId:string;
  locationId:string;
  locationName:string;
}

class CalendarService {
  public async getLocationsAsync(): Promise<Location[]> {
    const token = await AuthService.getAuthHeader();
    const response: AxiosResponse<Location[]> = await axios({
      method: 'GET',
      url: `${CALENDAR_API_URL}/api/locations`,
      headers: {
        Authorization: token
      }
    });

    return response.data;
  }

  public async getDefaultUserLocation(userId:string):Promise<DefaultLocationResponse | string>{
    const token = await AuthService.getAuthHeader();
    const response = await axios({
      method: 'GET',
      url: `${CALENDAR_API_URL}/api/locations/getdefaultlocation`,
      params:{
        id:userId
      },
      headers: {
        Authorization: token
      }
    });
    return response.data;
  }

  public async getServices():Promise<Services[]>{
    const token = await AuthService.getAuthHeader();
    const response = await axios({
      method: 'GET',
      url: `${CALENDAR_API_URL}/api/services`,
      headers: {
        Authorization: token
      }
    });
    return response.data;
  }

  public async createOrUpdateCalendarEvent(command: CreateTaskCommand | DragAndDropUpdateCommand, id: string = ''): Promise<AxiosResponse<string>> {
    const token = await AuthService.getAuthHeader();
    return axios({
      method: id ? 'PUT' : 'POST',
      url: `${CALENDAR_API_URL}/api/tasks/${id}`,
      data:{ ...command },
      headers: {
        Authorization: token
      }
    });
  }

  
  public async deleteEvent(id: string): Promise<AxiosResponse> {
    const token = await AuthService.getAuthHeader();
    return axios({
      method: 'DELETE',
      url: `${CALENDAR_API_URL}/api/tasks`,
      params: {
        id
      },
      headers: {
        Authorization: token
      }
    });
  }

  public async getLocationEventsAsync(location: Location, weekStartDate: Date): Promise<Task[]> {
    const token = await AuthService.getAuthHeader();
    const response: AxiosResponse<TaskResponse[]> = await axios({
      method: 'GET',
      url: `${CALENDAR_API_URL}/api/tasks`,
      params: {
        locationId: location.locationId,
        weekStartDate: weekStartDate.toISOString()
      },
      headers: {
        Authorization: token
      }
    });

    const events:Task[] = response.data.map<Task>( event => ({
      ...event,
      ...expandWithFields(event.from , event.to)
    }));
    
    return events;
  }

  public async getTaskAsync(id: string): Promise<Task> {
    const authorizationHeaderValue = await AuthService.getAuthHeader();
    const response = await axios.get<TaskResponse>(`${CALENDAR_API_URL}/api/tasks/${id}`,{
      headers: {
        Authorization: authorizationHeaderValue
      }
    });

    const taskResponse = response.data;

    return {
      ...response.data,
      ...expandWithFields(taskResponse.from , taskResponse.to)
    };
  }

  public async getRampsSchedule(locationId:string):Promise<RapmsSchedule>{
    const token = await AuthService.getAuthHeader();
    const response = await axios({
      method: 'GET',
      url: `${CALENDAR_API_URL}/api/ramps/${locationId}`,
      headers: {
        Authorization: token
      }
    });

    return response.data;
  }

  public async createDefaultLocation(userId:string, locationId:string):Promise<string>{
    const token = await AuthService.getAuthHeader();
    const response = await axios({
      method: 'GET',
      url: `${CALENDAR_API_URL}/api/locations/createdefaultlocation`,
      params: {
        userId,
        locationId
      },
      headers: {
        Authorization: token
      }
    });

    return response.data;
  }
 
  public async updateDefaultLocation(defaultlocationrecordId:string , locationId:string):Promise<string>{
    const token = await AuthService.getAuthHeader();
    const response =  await axios({
      method: 'GET',
      url: `${CALENDAR_API_URL}/api/locations/updatedefaultlocation`,
      params: {
        defaultlocationrecordId,
        locationId
      },
      headers: {
        Authorization: token
      }
    });

    return response.data;
    
  }

}

const calendarService = new CalendarService();
export default calendarService;
