import { FunctionComponent, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center',
    gap:5,
    marginTop: '10px',
    maxWidth: '810px',
  },
  fieldTitle:{
    minWidth: 115
  },
  leftIcon: {
    paddingRight: '10px'
  },
  fullWidth: {
    width: '100%'
  }
});

interface FormRowProps {
  title: string;
  alignSelfStart?:boolean;
}

const FormRow: FunctionComponent<FormRowProps> = (props): ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <div className={classes.fieldTitle} style={props?.alignSelfStart ? {alignSelf:'flex-start'} : {}}>
        <strong>{props.title}</strong>
      </div>
      <div className={classes.fullWidth}>{props.children}</div>
    </div>
  );
};

export default FormRow;
