import {useEffect, ChangeEvent, FunctionComponent, ReactElement, useState } from "react";
import {
  MenuItem,
  Switch,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { LocationsState } from "../../reducers/locations-reducer";
import {format, addMinutes} from 'date-fns';
import CalendarService, { Location , RapmsSchedule, DaySchedule} from "../../services/calendar-service";
import CalendarIcon from "../../icons/calendar-icon";
import RightArrowIcon from "../../icons/right-arrow-icon";
import { getDuration, extractErrorMessage, createOrEditTask} from "../../unit/utils";
import {getDateWithHours , parseTimeString} from './event-container';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import * as Labels from "../../unit/labels";
import Input from "./input";
import SelectStyled from "./select-styled";
import DatePickerStyled from "./date-picker-styled";
import ButtonStyled from "./button-styled";
import useRamps from '../../hooks/use-ramps';
import AlertStyled from "./alert-styled";
import useStyles from "./use-styles";
import FormRow from "./form-row";
import { navigateToCalendar } from '../navigate';
import { TimePicker } from '@material-ui/pickers';

const PurpleSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#6264a7',
    },
    '&$checked + $track': {
      backgroundColor: '#6264a7',
    },
  },
  checked: {},
  track: {},
})(Switch);

export interface FormState {
  locationId: string;
  title?: string;
  note?: string;
  from: Date;
  to: Date;
  isWholeDay: boolean;
  date: Date;
  serviceId:string;
  rampFrom:Date | null;
  rampTo:Date | null;
  rampId:string;
  calculatedEndTime:Date | null;
  mark:string;
  customerOnSite:boolean;
}

interface SubmitState {
  submitting: boolean;
  error: boolean;
  message: string;
}

// const getTimeString = (date: Date): string => {
//   const hours = date.getHours();
//   const minutes = date.getMinutes();
//   return `${hours}:${minutes}`;
// };

const submitStateDefault: SubmitState = {
  submitting: false,
  error: false,
  message: "",
};

interface EventDetailsProps {
  id?: string;
  locationId: string;
  serviceId:string;
  title?: string;
  note?: string;
  from: Date;
  to: Date;
  isWholeDay: boolean;
  date: Date;
  activeLocation: Location;
  locations: Location[];
  rampFrom:Date | null;
  rampTo:Date | null;
  rampId:string;
  mark:string;
  customerOnSite:boolean;
}

const EventPage: FunctionComponent<EventDetailsProps> = (
  props
): ReactElement | null => {
  const {activeLocation, locations,  ...rest} = props;
  const id = props.id;
  const [form, setForm] = useState<FormState>({ ...rest , calculatedEndTime:null});
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [rampsSchedule , setRampsSchedule] = useState<RapmsSchedule>([]);
  const [submitState, setSubmitState] = useState<SubmitState>(submitStateDefault);
  const {services} = useSelector<RootState , LocationsState>(state => state.calendar);
  const { rampId , isWholeDay, serviceId , from} = form;
  useRamps(
    form.locationId, 
    (ramps:RapmsSchedule) => setRampsSchedule(ramps)
  );

  useEffect(()=>{
    if(isWholeDay){
      setForm(form => ({ ...form, serviceId:'', calculatedEndTime:null }));
    }
   }, [isWholeDay]);

   useEffect(()=>{
    if(rampId && rampsSchedule.length && isWholeDay){
      const rampIndex = rampsSchedule.findIndex( ramp => ramp.id === form.rampId);
      let day:DaySchedule | null = null;
      for (let i = 0; i < rampsSchedule[rampIndex].schedule.length; i++){
        const dayItem = rampsSchedule[rampIndex].schedule[i];
        if(dayItem.day === format(form.date, 'EEEE')){
           day = dayItem;
           break;
        }
      }
      if(day){
        setForm(form => (
          { ...form, 
            rampFrom: getDateWithHours(...parseTimeString(day!.from)),
            rampTo:getDateWithHours(...parseTimeString(day!.to)),
          }));
      }
      
    }
    // eslint-disable-next-line
  }, [rampId , rampsSchedule, isWholeDay]);

  const getEndTime = (duration:number, startTime:Date):Date=>{
    const date = new Date(startTime);
    return addMinutes(date , duration);
  }

  useEffect(()=>{
    if(serviceId && !isWholeDay){
      const service = services.find( s => s.id === serviceId);
      if(service){
        setForm(form => (
          { 
            ...form, 
            calculatedEndTime:getEndTime(service.duration, from)
          }));
      }
    }
    // eslint-disable-next-line
  }, [serviceId, isWholeDay, from]);

  const changeHandler = (
    event: ChangeEvent<{ value: unknown; name?: string }>
  ): void => {
    const name = event.target.name;
    const value = event.target.value as string;
    if (name) {
      setForm((form) => ({ ...form, [name]: value }));
    }
  };

  const onChangeHandler = (
    event: ChangeEvent<{ value: unknown; name?: string }>
  ): void => {
    const name = event.target.name;
    const value = event.target.value as string;
    if (!name) {
      return;
    }
    
    setForm((state) => ({ ...state, [name]: value }));
  };

  const dateChangeHandler = (date: MaterialUiPickersDate): void => {
    if (date) {
      setForm((form) => ({ ...form, date: date }));
    }
  };

  const switchChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    const name = event.target.name;
    if (name) {
      setForm((form) => ({ ...form, [name]: checked }));
    }
  };

  const submitHandler = async (): Promise<void> => {
    if (submitState.submitting) {
      return;
    }
    let errorMessage = "";
    try {
      setSubmitState((state) => ({
        ...state,
        message: "",
        error: false,
        submitting: true,
      }));
      await CalendarService.createOrUpdateCalendarEvent(createOrEditTask({...form}), id || undefined);
      navigateToCalendar();
    } catch (err:any) {
      const error = extractErrorMessage(err);
      errorMessage = error.errorMessage;
    } finally {
      setSubmitState((state) => ({
        ...state,
        error: !!errorMessage,
        message: errorMessage,
        submitting: false,
      }));
    }
  };

  const closeHandler = (): void => {
    if (submitState.submitting) {
      return;
    }
    navigateToCalendar();
  };

  const clearErrorHandler = ():void=>{
    setSubmitState( submitState => ({...submitState , error:false, message:''}))
  }

  const deleteHandler = async (): Promise<void> => {
    if (id) {
      await CalendarService.deleteEvent(id);
    }
    navigateToCalendar();
  };

  const toValueChangeHandler = (date: MaterialUiPickersDate): void => {
    if (date) {
      setForm(state => ({
        ...state,
        to: date
      }));
    }
  };

  const fromValueChangeHandler = (date: MaterialUiPickersDate): void => {
    if (date) {
      setForm(state => ({
        ...state,
        from: date
      }));
    }
  };

  const TimePickerFromValue = form.isWholeDay ? form.rampFrom : form.from;
  const TimePickerToValue = form.isWholeDay ? form.rampTo : form.calculatedEndTime ? form.calculatedEndTime : form.to;

  return (
    <div className={classes.container}>
      <AlertStyled visible={submitState.error} onErrorHandler={clearErrorHandler}>
        {submitState.message}
      </AlertStyled>
      <div className={classes.titleBar}>
        <i className={classes.calendarIcon}>
          <CalendarIcon className={classes.appSvg} />
        </i>
        {md && <h2 className={classes.subject}>{Labels.NewTask}</h2>}
        <div className={classes.bridge} />
        {id && (
          <ButtonStyled
            className={classes.saveBtn}
            variant="contained"
            size="small"
            color="primary"
            onClick={deleteHandler}
          >
            {Labels.Delete}
          </ButtonStyled>
        )}
        <ButtonStyled
          className={classes.saveBtn}
          variant="contained"
          size="small"
          color="primary"
          onClick={submitHandler}
        >
          {Labels.Save}
        </ButtonStyled>
        <ButtonStyled variant="contained" size="small" onClick={closeHandler}>
          {Labels.Close}
        </ButtonStyled>
      </div>
      <div className={classes.formContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormRow title={'Bezeichnung'}>
            <Input
              onChange={changeHandler}
              value={form.title}
              name="title"
              fullWidth
            />
          </FormRow>

          <FormRow title={'Kennzeichen'}>
            <Input
              onChange={changeHandler}
              value={form.mark}
              name="mark"
              fullWidth
            />
          </FormRow>

          <FormRow title={'Standort'}>
            <div className={classes.locationRow}>
              <div className={classes.locationSelectContainer}>
                <SelectStyled
                  value={form.locationId}
                  name="locationId"
                  onChange={onChangeHandler}
                  fullWidth
                >
                  {locations!.map((location) => (
                    <MenuItem key={location.locationId} value={location.locationId}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span>{location.locationName}</span>
                      </div>
                    </MenuItem>
                  ))}
                </SelectStyled>
              </div>
            </div>
          </FormRow>

          <FormRow title={'Platz'}>
            <div className={classes.locationRow}>
              <div className={classes.locationSelectContainer}>
                <SelectStyled
                  value={form.rampId}
                  name="rampId"
                  onChange={onChangeHandler}
                  fullWidth
                >
                  {rampsSchedule.map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span>{r.name}</span>
                      </div>
                    </MenuItem>
                  ))}
                </SelectStyled>
              </div>
            </div>
          </FormRow>

          <FormRow title={'Bewertungsart'}>
            <div className={classes.locationRow}>
              <div className={classes.locationSelectContainer}>
                <SelectStyled
                  value={form.serviceId}
                  name="serviceId"
                  onChange={onChangeHandler}
                  disabled={form.isWholeDay}
                  fullWidth
                >
                  {services.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span>{s.name}</span>
                      </div>
                    </MenuItem>
                  ))}
                </SelectStyled>
              </div>
            </div>
          </FormRow>

          <FormRow title={'Kunde Vor Ort'}>
            <div className={classes.locationRow}>
              <div className={classes.locationSelectContainer}>
              <FormControlLabel
                control={<PurpleSwitch checked={form.customerOnSite} onChange={switchChangeHandler} name='customerOnSite' inputProps={{ 'aria-label': 'Customer on site' }} />}
                label=''
              />
              </div>
            </div>
          </FormRow>

          <FormRow title={'Termin'}>
            <DatePickerStyled
              disableToolbar
              variant="inline"
              name="date"
              fullWidth
              value={form.date}
              onChange={dateChangeHandler}
            />
          </FormRow>

          <FormRow title={''}>
            <div
              style={{ display: "flex", flexDirection: md ? "row" : "column" }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
              <TimePicker 
                disableToolbar 
                variant='inline' 
                name='from' 
                disabled={form.isWholeDay} 
                ampm={false} 
                value={TimePickerFromValue} 
                onChange={fromValueChangeHandler} 
              />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <RightArrowIcon className={classes.appSvg} />
                </div>
                <TimePicker 
                 disableToolbar 
                 variant='inline' 
                 name='to' 
                 disabled={form.isWholeDay || !!form.calculatedEndTime} 
                 ampm={false} 
                 value={TimePickerToValue} 
                 onChange={toValueChangeHandler}
                 />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  {getDuration(TimePickerFromValue, TimePickerToValue, form.isWholeDay)}
                </span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={form.isWholeDay}
                      onChange={switchChangeHandler}
                      name="isWholeDay"
                      inputProps={{ "aria-label": "All day" }}
                    />
                  }
                  label={Labels.AllDay}
                />
              </div>
            </div>
          </FormRow>

          <FormRow title={'Notiz'} alignSelfStart={true}>
            <Input
              name="note"
              onChange={changeHandler}
              fullWidth
              multiline
              rows={10}
            />
          </FormRow>
        </div>
      </div>
    </div>
  );
};
export default EventPage;
